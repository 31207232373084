import React,{ useState,useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector} from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { language } from 'config';

export default function Income() {

    const columns =  [
        { title: language.report_year,field: 'report_year'},
        { title: language.report_month, field: 'monthName' },
        { title: language.product_gross, field: 'serviceCost' },
        { title: language.product_nett, field: 'nettCost' },
        { title: language.report_ourfees, field: 'serviceFee' },
        { title: language.product_discount, field: 'report_discount' },
        { title: language.report_profit,  field: 'profit', render: rowData => rowData.serviceFee - rowData.report_discount , editable:'never'},
        
    ];

  const [data, setData] = useState([]);
  const revenuedata = useSelector(state => state.revenueState);

  useEffect(()=>{
        if(revenuedata.Income){
            setData(revenuedata.Income);
        }
  },[revenuedata.Income]);

  return (
    revenuedata.loading? <CircularLoading/>:
    <MaterialTable
      title={language.title_view_income}
      columns={columns}
      data={data}
      options={{
        exportButton: true,
        headerStyle: {
          backgroundColor: '#ee2324',
          color: '#FFF'
        }
      }}
      
    />
  );
}
