import React, { useState, useEffect, useContext } from 'react';
import {
  Select,
  MenuItem,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  TextField
} from '@material-ui/core';
import GoogleMapsAutoComplete from '../components/GoogleMapsAutoComplete';
import { useSelector, useDispatch } from "react-redux";
import AlertDialog from '../components/AlertDialog';
import { language } from 'config';
import { makeStyles } from '@material-ui/core/styles';
import UsersCombo from '../components/UsersCombo';
import { FirebaseContext } from 'common';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    alignContent: 'center'
  },
  title: {
    color: "#000",
  },
  gridcontainer: {
    alignContent: 'center'
  },
  items: {
    margin: 0,
    width: '100%'
  },
  input: {
    fontSize: 18,
    color: "#000"
  },
  inputdimmed: {
    fontSize: 18,
    color: "#737373"
  },
  productPic: {
    height: '18px',
    marginRight: '10px'
  },
  buttonStyle: {
    margin: 0,
    width: '100%',
    height: '100%'
  }
}));

export default function NewOrder(props) {
  const { api } = useContext(FirebaseContext);
  const {
    estimateTravel, 
    estimateClear,
    requestVehicle, 
    requestClear,
    DateFunctionsMinutesPassed,
    DateFunctionsDateString
  } = api;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [alert, setAlert] = useState({ open: false, msg: '' });
  const [endAddress, setEndAddress] = useState(null);
  const [estimateModalStatus, setEstimateModalStatus] = useState(false);
  const [estimateRequested, setEstimateRequested] = useState(false);
  const [product, setProduct] = useState(language.input_select_product);
  const [schedule, setSchedule] = useState(language.input_schedule_now);
  const [selectedDate, setSelectedDate] = useState(DateFunctionsDateString());
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [startAddress, setStartAddress] = useState(null);
  const [subsCombo, setSubsCombo] = useState(null);
  const [subscribers, setSubscriber] = useState(null);
  const estimatedata = useSelector(state => state.estimateState);
  const productTypes = useSelector(state => state.productState.product_type);
  const request = useSelector(state => state.requestState);
  const settings = useSelector(state => state.settingState.settings);
  const subscriberData = useSelector(state => state.subscriberState);

  const handleProductSelect = (event) => {
    setProduct(event.target.value);
    let product = null;
    for (let i = 0; i < productTypes.length; i++) {
      if (productTypes[i].name === event.target.value) {
        product = productTypes[i];
      }
    }
    setSelectedProduct(product);
  };

  const handleScheduleSelect = (event) => {
    setSchedule(event.target.value);
    if (schedule === language.input_schedule_later) {
      setSelectedDate(DateFunctionsDateString());
    }
  };

  const onDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  useEffect(() => {
    if (estimatedata.estimate && estimateRequested) {
      setEstimateRequested(false);
      setEstimateModalStatus(true);
    }
    if (subscriberData.subscribers) {
      let arr = [];
      for (let i = 0; i < subscriberData.subscribers.length; i++) {
        let subscriber = subscriberData.subscribers[i];
        if (subscriber.role === 'subscriber') {
          arr.push({
            'first_name': subscriber.first_name,
            'last_name': subscriber.last_name,
            'mobile': subscriber.mobile,
            'email': subscriber.email,
            'uid': subscriber.id,
            'sort': subscriber.first_name + ' ' + subscriber.last_name + ' (' + subscriber.mobile + ') ' + subscriber.email,
            'token': subscriber.token
          });
        }
      }
      setSubscriber(arr);
    }
  }, [estimatedata.estimate, subscriberData.subscribers, estimateRequested]);


  const handleGetEstimate = (e) => {
    e.preventDefault();
    setEstimateRequested(true);
    if (subsCombo && startAddress && endAddress && selectedProduct) {
      if (schedule === language.input_schedule_now) {
        dispatch(estimateTravel({
          start_location: startAddress,
          end_location: endAddress,
          product: selectedProduct,
        }));
      } else {
        if (schedule === language.input_schedule_later && selectedDate) {
          if (DateFunctionsMinutesPassed(selectedDate) >= 15) {
            dispatch(estimateTravel({
              start_location: startAddress,
              end_location: endAddress,
              product: selectedProduct,
            }));
          } else {
            setAlert({ open: true, msg: language.error_schedule });
          }
        } else {
          setAlert({ open: true, msg: language.error_insufficient });
        }
      }
    } else {
      setAlert({ open: true, msg: language.error_insufficient })
    }
  };

  const confirmSalesOrder = (e) => {
    e.preventDefault();
    setEstimateModalStatus(false);
    let salesObject = {
      start_location: startAddress,
      end_location: endAddress,
      product: selectedProduct,
      subscriber: {
        uid:subsCombo.uid,
        profile:{
          first_name: subsCombo.first_name,
          last_name: subsCombo.last_name,
          mobile: subsCombo.mobile,
          token: subsCombo.token
        }
      },
      estimate: estimatedata.estimate,
      order_date: new Date(selectedDate).toString(),
      schedule: schedule === language.input_schedule_later ? true : false,
      settings: settings,
      weborder: true
    };
    dispatch(requestVehicle(salesObject));
  };

  const handleEstimateModalClose = (e) => {
    e.preventDefault();
    setEstimateModalStatus(false);
    dispatch(estimateClear());
    setEstimateRequested(false);
  };

  const handleEstimateErrorClose = (e) => {
    e.preventDefault();
    dispatch(estimateClear());
    setEstimateRequested(false);
  };

  const handleSalesOrderAlertClose = (e) => {
    e.preventDefault();
    dispatch(requestClear());
    dispatch(estimateClear());
    clearForm();
  };

  const clearForm = () => {
    setSubsCombo(null);
    setStartAddress(null);
    setEndAddress(null);
    setSelectedProduct(null);
    setProduct(language.input_select_product);
    setSchedule(language.input_schedule_now);
    setEstimateRequested(false);
  }

  const handleSalesOrderErrorClose = (e) => {
    e.preventDefault();
    dispatch(requestClear());
    setEstimateRequested(false);
  };

  const alertClose = (e) => {
    e.preventDefault();
    setAlert({ open: false, msg: '' })
  };

  return (
    <div className={classes.container}>
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <Grid container spacing={2} >
          <Grid item xs={12}>
            <Typography component="h1" variant="h5" className={classes.title}>
              {language.assignment_new}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {subscribers ?
              <UsersCombo
                className={classes.items}
                placeholder={language.input_select_subscriber}
                subscribers={subscribers}
                value={subsCombo}
                onChange={(event, newValue) => {
                  setSubsCombo(newValue);
                }}
              />
              : null}
          </Grid>
          <Grid item xs={12}>
            <GoogleMapsAutoComplete
              variant={"outlined"}
              placeholder={language.input_location}
              value={startAddress}
              className={classes.items}
              onChange={
                (value) => {
                  setStartAddress(value);
                }
              }
            />
          </Grid>
          <Grid item xs={12}>
            <GoogleMapsAutoComplete placeholder={language.location_final}
              variant={"outlined"}
              value={endAddress}
              className={classes.items}
              onChange={
                (value) => {
                  setEndAddress(value);
                }
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {productTypes ?
              <Select
                id="product"
                value={product}
                onChange={handleProductSelect}
                variant="outlined"
                fullWidth
                className={product === language.input_select_product ? classes.inputdimmed : classes.input}
              >
                <MenuItem value={language.input_select_product} key={language.input_select_product}>
                  {language.input_select_product}
                </MenuItem>
                {
                  productTypes.map((product) =>
                    <MenuItem key={product.name} value={product.name}>
                      <img src={product.image} className={classes.productPic} alt="product_image" />{product.name}
                    </MenuItem>
                  )
                }
              </Select>
              : null}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Select
              id="schedule_select"
              value={schedule}
              onChange={handleScheduleSelect}
              className={classes.input}
              variant="outlined"
              fullWidth
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem key={language.input_schedule_now} value={language.input_schedule_now}>
                {language.input_schedule_now}
              </MenuItem>
              <MenuItem key={language.input_schedule_later} value={language.input_schedule_later}>
                {language.input_schedule_later}
              </MenuItem>
            </Select>
          </Grid>
          {schedule === language.input_schedule_later ?
            <Grid item xs={12} sm={6} >
              <TextField
                id="datetime-local"
                label={language.date_time}
                type="datetime-local"
                variant="outlined"
                fullWidth
                className={classes.commonInputStyle}
                InputProps={{
                  className: classes.input
                }}
                value={selectedDate}
                onChange={onDateChange}
              />
            </Grid>
            : null}
          <Grid item xs={12} sm={6} >
            <Button
              size="large"
              onClick={handleGetEstimate}
              variant="contained" 
              color="primary"
              className={classes.buttonStyle}
            >
              <i className="fas fa-estimate" />
              {language.input_schedule}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        open={estimateModalStatus}
        onClose={handleEstimateModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{language.product_estimate}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {settings?settings.symbol:null} {estimatedata.estimate ? estimatedata.estimate.estimated_total : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEstimateModalClose} color="primary">
            {language.input_cancel}
          </Button>
          <Button onClick={confirmSalesOrder} color="primary" autoFocus>
            {language.input_schedule_now}
          </Button>
        </DialogActions>
      </Dialog>
      <AlertDialog open={request.order ? true : false} onClose={handleSalesOrderAlertClose}>{request.order ? language.assignment_requested + request.order.oid : null}</AlertDialog>
      <AlertDialog open={request.error.flag} onClose={handleSalesOrderErrorClose}>{request.error.msg}</AlertDialog>
      <AlertDialog open={estimatedata.error.flag} onClose={handleEstimateErrorClose}>{estimatedata.error.msg}</AlertDialog>
      <AlertDialog open={alert.open} onClose={alertClose}>{alert.msg}</AlertDialog>
    </div>
  );
}