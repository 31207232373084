import React,{ useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { 
  appConfig ,
  dateStyle,
  language
} from 'config';
import { FirebaseContext } from 'common';

export default function Subscribers() {
  const { api } = useContext(FirebaseContext);
  const {
    subscriberCreate,
    subscriberEdit, 
    authDelete,
    authUserExist
  } = api;
  const [data, setData] = useState([]);
  const subscriberData = useSelector(state => state.subscriberState);
  const dispatch = useDispatch();

  const modedata = useSelector(state => state.modeState);
  const [mode, setMode] = useState({});

  useEffect(()=>{
    if(modedata.modes){
        let obj =  {};
        modedata.modes.map((appmode)=> obj[appmode.name]=appmode.name)
        setMode(obj);
    }
  },[modedata.modes]);

  // how to filter database tables / rows
  useEffect(()=>{
    if(subscriberData.subscribers){
      setData(subscriberData.subscribers.filter(user => user.role !=='admin'));
    }else{
      setData([]);
    }
  },[subscriberData.subscribers]);

  const columns = [
    { title: language.account_created, field: 'account_created', editable:'never', defaultSort:'sort',render: rowData => rowData.account_created ? new Date(rowData.account_created).toLocaleString(dateStyle) : null},
    { title: language.mode_app, field: 'app_mode', lookup: mode},
    { title: language.subscriber_first_name, field: 'first_name'},
    { title: language.subscriber_last_name, field: 'last_name'},
    { title: language.subscriber_email, field: 'email', editable:'onAdd'},
    { title: language.subscriber_mobile, field: 'mobile', editable:'onAdd'},
    { title: language.subscriber_picture,  field: 'picture', render: rowData => rowData.picture?<img alt='Profile' src={rowData.picture} style={{width: 50,borderRadius:'50%'}}/>:null, editable: 'never'},
    { title: language.account_approved,  field: 'account_approved', type:'boolean'},
    { title: language.account_balance,  field: 'account_balance', type:'numeric' , editable:'never', initialEditValue: 0},
    { title: language.report_referrer_code, field: 'referrer_code', editable:'never' },
    { title: language.report_referral_code,  field: 'referral_code', editable:'never', initialEditValue: '' },
  ];

  return (
    subscriberData.loading? <CircularLoading/>:
    <MaterialTable
      title={language.title_view_subscribers}
      columns={columns}
      data={data}
      options={{
        exportButton: true,
        sorting: true,
        headerStyle: {
          backgroundColor: '#ee2324',
          color: '#FFF'
        }
      }}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve,reject) => {
            setTimeout(() => {
              authUserExist(newData).then((res) => {
                if (res.subscribers && res.subscribers.length > 0) {
                  alert(language.subscribers_exists);
                  reject();
                }
                else if(res.error){
                  alert(language.subscriber_credentials_error);
                  reject();
                }
                else{
                  let number = newData['mobile'];
                  number = number.replace(/ /g, '');
                  number = number.replace(/-/g, '');
                  newData['mobile'] = number.substr(-9);
                  newData['admin_added'] = true;
                  newData['role'] = 'subscriber';
                  newData['account_created'] = new Date().toISOString();
                  newData['referral_code'] = newData.first_name.toLowerCase() + Math.floor(1000 + Math.random() * 9000).toString();
                  dispatch(subscriberCreate(newData));
                  resolve();
                }
              });
            }, 600);
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              dispatch(subscriberEdit(oldData.id,newData));
            }, 600);
          }),
        onRowDelete: oldData =>
          appConfig.productionMode?
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              dispatch(authDelete(oldData.id));
            }, 600);
          })
          :
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              alert(language.demo_mode);
            }, 600);
          })
          , 
      }}
    />
  );
}
