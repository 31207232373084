import React,{ useState,useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { language } from 'config';

export default function Earnings() {

    const columns =  [
        { title: language.report_year,field: 'report_year'},
        { title: language.report_month, field: 'monthName' },
        { title: language.vendor_name, field: 'vendor_name'},
        { title: language.report_number, field: 'product_number' },
        { title: language.payment_amount, field: 'vendor_share' },
        
    ];

  const [data, setData] = useState([]);
  const earningData = useSelector(state => state.earningState);

  useEffect(()=>{
        if(earningData.earnings){
            setData(earningData.earnings);
        }
  },[earningData.earnings]);

  return (
    earningData.loading? <CircularLoading/>:
    <MaterialTable
      title={language.title_view_earnings}
      columns={columns}
      data={data}
      options={{
        exportButton: true,
        grouping: true,
        headerStyle: {
          backgroundColor: '#ee2324',
          color: '#FFF'
        }
      }}
    />
  );
}
