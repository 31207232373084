import React,{ useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
import CircularLoading from "../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import ConfirmationDialogRaw from '../components/ConfirmationDialogRaw';
import { 
  appConfig,
  dateStyle,
  language
} from 'config';
import { FirebaseContext } from 'common';

const SalesOrders = () => {
  const { api } = useContext(FirebaseContext);
  const {
    orderCancel
  } = api;
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const [role, setRole] = useState(null); 
  const columns =  [
      { title: language.report_order_id, field: 'id' },
      { title: language.report_status, field: 'order_status', render: revenue => <span>{language[revenue.order_status]}</span> },
      { title: language.report_date, field: 'invoice_date', render: revenue => revenue.invoice_date?new Date(revenue.invoice_date).toLocaleString(dateStyle):new Date(revenue.order_date).toLocaleString(dateStyle)},
      { title: language.product_type, field: 'product_type' },
      { title: language.vendor_name,field: 'vendor_name'},
      { title: language.report_number, field: 'product_number' },
      { title: language.location_start, field: 'start_location' },
      { title: language.location_end, field: 'end_location' },
      { title: language.report_distance, field: 'total_distance' },
      { title: language.report_otp, field: 'otp', render: revenue => revenue.order_status ==='NEW' || revenue.order_status === 'ACCEPTED' ?<span>{revenue.otp}</span>:null },
      { title: language.report_time_start, field: 'local_start_time' },
      { title: language.report_time_end, field: 'local_end_time' },
      { title: language.report_duration, field: 'total_duration' },
      { title: language.product_nett, field: 'vendor_share'},
      { title: language.product_service_fee, field: 'service_fee'},
      { title: language.product_discount, field: 'discount'},
      { title: language.product_total, field: 'total_cost' },
      { title: language.payment_done, field: 'subscriber_paid'},
      { title: language.payment_method, field: 'payment_method'},
      { title: language.payment_gateway, field: 'gateway'},
      { title: language.payment_cash, field: 'cash_payment'},
      { title: language.payment_card, field: 'card_payment'},
      { title: language.payment_account, field: 'account_payment'},
      { title: language.title_cancel_reason, field: 'metadata'}
  ];
  const [data, setData] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState('');
  const salesOrders = useSelector(state => state.orderState);

  useEffect(()=>{
        if(salesOrders.orders){
            setData(salesOrders.orders);
        }else{
          setData([]);
        }
  },[salesOrders.orders]);

  useEffect(() => {
    if(auth.info && auth.info.profile){
      setRole(auth.info.profile.role);
    }
  }, [auth.info]);

  const onConfirmClose=(value)=>{
    if(value){
      dispatch(orderCancel({
        metadata:value,
        revenue:selectedOrder
      }));
    }
    setOpenConfirm(false);
  }
  
  return (
    salesOrders.loading? <CircularLoading/>:
    <div>
    <MaterialTable
      title={language.title_view_sales}
      columns={columns}
      data={data}
      options={{
        actionsColumnIndex: -1,
        headerStyle: {
          backgroundColor: '#ee2324',
          color: '#FFF'
        }
      }}
      actions={[
        revenue => ({
          icon: 'cancel',
          tooltip: language.input_cancel,
          disabled: revenue.order_status==='NEW' || revenue.order_status==='ACCEPTED'? false:true,
          onClick: (event, revenue) => {
            if(appConfig.productionMode && (role==='subscriber' || role ==='admin')){
              setSelectedOrder(revenue);
              setOpenConfirm(true);
            }else{
              alert(language.demo_mode);
            }
          }         
        }),
      ]}
    />
    <ConfirmationDialogRaw
      open={openConfirm}
      onClose={onConfirmClose}
      value={''}
    />
    </div>

  );
}

export default SalesOrders;