import React, { useState, useEffect, useContext } from 'react';
import classNames from "classnames";
import { makeStyles } from '@material-ui/core/styles';
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import {
  Paper,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import GoogleMapsAutoComplete from '../components/GoogleMapsAutoComplete';
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import Products from "./Sections/Products.js";
import Download from "./Sections/Download.js";
import { useSelector, useDispatch } from "react-redux";
import AlertDialog from '../components/AlertDialog';
import {language} from 'config';
import { FirebaseContext } from 'common';

const dashboardRoutes = [];
let DistancePrice = 0;

const useStyles = makeStyles(styles);

export default function Home(props) {
  const { api } = useContext(FirebaseContext);
  const {
    estimateTravel,
    estimateService, 
    estimateClear,
    requestVehicle,
    requestService, 
    requestClear,
    DateFunctionsMinutesPassed,
    DateFunctionsDateString
  } = api;
  
  const classes = useStyles();
  const dispatch = useDispatch();
  const { ...rest } = props;
  const auth = useSelector(state => state.auth);
  const [alert, setAlert] = useState({ open: false, msg: '' });
  const [endAddress, setEndAddress] = useState(null);
  const [estimateModalStatus, setEstimateModalStatus] = React.useState(false);
  const [orderDuration, setSalesOrderDuration] = useState(3600);
  const [product, setProduct] = useState(language.input_select_product);
  const [schedule, setSchedule] = useState(language.input_schedule_now);
  const [selectedDate, setSelectedDate] = useState(DateFunctionsDateString());
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [startAddress, setStartAddress] = useState(null);
  const [videoDuration, setVideoDuration] = useState(600);
  const estimatedata = useSelector(state => state.estimateState);
  const products = useSelector(state => state.productState.product_type);
  const request = useSelector(state => state.requestState);
  const settings = useSelector(state => state.settingState.settings);

  const handleProductSelect = (event) => {
    setProduct(event.target.value);
    let product = null;
    for (let i = 0; i < products.length; i++) {
      if (products[i].name === event.target.value) {
        product = products[i];
        // Non Travel
        DistancePrice = product.travel_rate;
      }
    }
    setSelectedProduct(product);
  };

  const handleScheduleSelect = (event) => {
      setSchedule(event.target.value);
      // Non Travel
      if(schedule===language.input_schedule_later || schedule==='Call'){
          setSelectedDate(DateFunctionsDateString());
      } 
  };

  const onDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  useEffect(() => {
    if (estimatedata.estimate) {
      setEstimateModalStatus(true);
    }
  }, [estimatedata.estimate]);

  const handleGetEstimate = (e) => {
    e.preventDefault();
    if (auth.info) {
      if (startAddress && selectedProduct) {
        if(schedule===language.input_schedule_now){
          // Non Travel right away
          if (DistancePrice === 0) {
            dispatch(estimateService({
              platform:'web',
              start_location: startAddress,
              product: selectedProduct,
              duration: schedule==='Call'?videoDuration:orderDuration,
              videoCall: false
            }));
          // Travel right away
          }else if(endAddress){
            dispatch(estimateTravel({
                platform:'web',
                start_location: startAddress,
                end_location: endAddress,
                product: selectedProduct,
                videoCall: false
              }));
          }
        }else{
          if((schedule===language.input_schedule_later || schedule==='Call') && selectedDate){
            // Scheduled Calls and Non Travel
            if(DateFunctionsMinutesPassed(selectedDate)>=15){
              if (DistancePrice === 0) {
                dispatch(estimateService({
                  platform:'web',
                  start_location: startAddress,
                  product: selectedProduct,
                  duration: schedule==='Call'?videoDuration:orderDuration,
                  videoCall: schedule==='Call'?true:false
                }));
              // Scheduled Travel
              }else{
                dispatch(estimateTravel({
                  platform:'web',
                  start_location: startAddress,
                  end_location: endAddress,
                  product: selectedProduct,
                  videoCall: schedule==='Call'?true:false
                }));
              }
            }else{
              setAlert({ open: true, msg: language.error_schedule });
            }
          }else{
            setAlert({ open: true, msg: language.error_insufficient });
          }
        }
      } else {
        setAlert({ open: true, msg: language.error_insufficient })
      }
    } else {
      setAlert({ open: true, msg: 'Hi there: ' + language.error_must_login })
    }
  };

  const confirmSalesOrder = (e) => {
    e.preventDefault();
    setEstimateModalStatus(false);

    // Non Travel
    if (DistancePrice === 0) {
      dispatch(requestService({
        start_location: startAddress,
        product: selectedProduct,
        subscriber: auth.info,
        estimate: estimatedata.estimate,
        order_date: new Date(selectedDate).toString(),
        schedule: schedule===language.input_schedule_later?true:false,
        settings:settings,
        weborder: true,
        duration: orderDuration
      }));
    } else {
      dispatch(requestVehicle({
        start_location: startAddress,
        end_location: endAddress,
        product: selectedProduct,
        subscriber: auth.info,
        estimate: estimatedata.estimate,
        order_date: new Date(selectedDate).toString(),
        schedule: schedule===language.input_schedule_later?true:false,
        settings:settings,
        weborder: true
      }));
    }
  };

  const handleEstimateModalClose = (e) => {
    e.preventDefault();
    setEstimateModalStatus(false);
    dispatch(estimateClear());
  };

  const handleEstimateErrorClose = (e) => {
    e.preventDefault();
    dispatch(estimateClear());
  };

  const handleSalesOrderAlertClose = (e) => {
    e.preventDefault();
    dispatch(requestClear());
    dispatch(estimateClear());
    props.history.push('/salesorders');
  };

  const handleSalesOrderErrorClose = (e) => {
    e.preventDefault();
    dispatch(requestClear());
  };

  const alertClose = (e) => {
    e.preventDefault();
    setAlert({ open: false, msg: '' })
  };

  // Non Travel
  const handleOrderDurationSelect = (event) => {
    setSalesOrderDuration(event.target.value);
  };

  const handleVideoDurationSelect = (event) => {
    setVideoDuration(event.target.value);
  };

  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/background.jpg")}>
        {auth.info && products?
          <div className={classes.container}>
            <GridContainer spacing={2}>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <br />
                <h1 className={classes.title}>{language.title_app_invite}</h1>
              </GridItem>
            </GridContainer>
            <GridContainer spacing={2}>
            <GridItem xs={6} sm={6} md={3} lg={3}>
                <FormControl style={{ width: '100%' }}>
                  <Select
                    id="product"
                    value={product}
                    onChange={handleProductSelect}
                    className={product === language.input_select_product ? classes.inputdimmed : classes.input}
                  >
                    <MenuItem value={language.input_select_product} key={language.input_select_product}>
                      {language.input_select_product}
                    </MenuItem>
                    {
                      products.filter(product => product.app_mode === auth.info.profile.app_mode).map((product) =>
                        <MenuItem key={product.name} value={product.name}>
                          <img src={product.image} className={classes.productPic} alt="product_image" />{product.name}
                        </MenuItem>
                      )
                    }
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={6} sm={6} md={3} lg={3}>
                <FormControl style={{ width: '100%' }}>
                  <Select
                    id="schedule"
                    value={schedule}
                    onChange={handleScheduleSelect}
                    className={classes.input}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem key={language.input_schedule_now} value={language.input_schedule_now}>
                      {language.input_schedule_now}
                    </MenuItem>
                    <MenuItem key={language.input_schedule_later} value={language.input_schedule_later}>
                      {language.input_schedule_later}
                    </MenuItem>
                    <MenuItem key={"Call"} value={"Call"}>
                      {language.input_schedule_video}
                    </MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
            <GridContainer spacing={2}>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <Paper >
                  <GoogleMapsAutoComplete 
                    placeholder={language.input_location}
                    variant={"filled"}
                    value={startAddress}
                    onChange={
                      (value) => {
                        setStartAddress(value);
                      }
                    }
                  />
                </Paper>
              </GridItem>
            </GridContainer>
            {DistancePrice > 0?
              <GridContainer spacing={2}>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <Paper>
                    <GoogleMapsAutoComplete 
                      placeholder={language.location_final}
                      variant={"filled"}
                      value={endAddress}
                      onChange={
                        (value) => {
                          setEndAddress(value);
                        }
                      }
                    />
                  </Paper>
                </GridItem>
              </GridContainer>
            :null}
            <div>
              <GridContainer spacing={2}>
                {schedule===language.input_schedule_later || schedule==='Call'?
                  <GridItem xs={6} sm={6} md={3} lg={3}>
                    <TextField
                      id="datetime-local"
                      label={language.date_time}
                      type="datetime-local"
                      variant="filled"
                      fullWidth
                      className={classes.commonInputStyle}
                      InputProps={{
                        className: classes.input
                      }}
                      value = {selectedDate}
                      onChange={onDateChange}
                    />
                  </GridItem>
                :null} 
                {product !== language.input_select_product && DistancePrice === 0?
                  <GridItem xs={6} sm={6} md={3} lg={3}>
                    {schedule==='Call'?
                      <FormControl style={{ width: '100%' }}>
                        <Select
                          id="video"
                          value={videoDuration}
                          onChange={handleVideoDurationSelect}
                          className={classes.input}                    
                        >                        
                          <MenuItem value={600}>
                            {language.input_minutes_twenty}
                          </MenuItem>
                          <MenuItem value={1800}>
                            {language.input_minutes_forty}
                          </MenuItem>
                          <MenuItem value={3600}>
                            {language.input_minutes_sixty}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    :  
                      <FormControl style={{ width: '100%' }}>
                        <Select
                          id="duration"
                          value={orderDuration}
                          onChange={handleOrderDurationSelect}
                          className={classes.input}                    
                        >                        
                          <MenuItem value={3600}>
                              {language.input_hours_one}
                            </MenuItem>
                            <MenuItem value={7200}>
                              {language.input_hours_two}
                            </MenuItem>
                            <MenuItem value={10800}>
                              {language.input_hours_three}
                            </MenuItem>
                            <MenuItem value={14400}>
                              {language.input_hours_four}
                            </MenuItem>
                            <MenuItem value={18000}>
                              {language.input_hours_five}
                            </MenuItem>
                            <MenuItem value={21600}>
                              {language.input_hours_six}
                            </MenuItem>
                            <MenuItem value={25200}>
                              {language.input_hours_seven}
                            </MenuItem>
                            <MenuItem value={28800}>
                              {language.input_hours_eight}
                            </MenuItem>
                        </Select>
                      </FormControl>
                    }
                  </GridItem>
                :null}
              </GridContainer>
            </div>
            <br />
              <GridContainer spacing={2}>
                <GridItem xs={6} sm={6} md={6} lg={6}>
                  <Button
                    color="success"
                    size="lg"
                    rel="noopener noreferrer"
                    className={classes.items}
                    onClick={handleGetEstimate}
                  >
                    {language.input_get_estimate}
                  </Button>
                </GridItem>
              </GridContainer>
          </div>
        : 
          <div className={classes.container}>
            <GridContainer spacing={2}>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <br />
                <h1 className={classes.title}>{language.title_slogan}</h1>
              </GridItem>
            </GridContainer>
          </div>
        }
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <Products />
        </div>
      </div>
      <div className={classNames(classes.main2, classes.mainRaised2)}>
        <div className={classes.container}>
          <Download />
        </div>
      </div>
      <Footer />
      <Dialog
        open={estimateModalStatus}
        onClose={handleEstimateModalClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{language.product_estimate}{" for "}{product?product:null}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {selectedProduct?selectedProduct.description:null} {settings?settings.symbol:null} {estimatedata.estimate ? estimatedata.estimate.estimated_total : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEstimateModalClose} color="primary">
            {language.input_cancel}
          </Button>
          <Button onClick={confirmSalesOrder} color="primary" autoFocus>
            {language.input_accept}
          </Button>
        </DialogActions>
      </Dialog>
      <AlertDialog open={request.order ? true : false} onClose={handleSalesOrderAlertClose}>{request.order ? language.assignment_requested + request.order.oid : null}</AlertDialog>
      <AlertDialog open={request.error.flag} onClose={handleSalesOrderErrorClose}>{request.error.msg}</AlertDialog>
      <AlertDialog open={estimatedata.error.flag} onClose={handleEstimateErrorClose}>{estimatedata.error.msg}</AlertDialog>
      <AlertDialog open={alert.open} onClose={alertClose}>{alert.msg}</AlertDialog>
    </div>
  );
}
