import React, { useState, useContext } from 'react';
import {
  Typography,
  ListItemIcon,
  Divider,
  Select,
  MenuList,
  MenuItem
} from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import { Link } from 'react-router-dom';
import { useSelector,useDispatch } from "react-redux";
import HomeIcon from '@material-ui/icons/Home';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import SettingsIcon from '@material-ui/icons/Settings';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import EditIcon from '@material-ui/icons/Edit';
import ListIcon from '@material-ui/icons/ListAlt';
import ExitIcon from '@material-ui/icons/ExitToApp';
import OfferIcon from '@material-ui/icons/LocalOffer';
import PeopleIcon from '@material-ui/icons/People';
import MoneyIcon from '@material-ui/icons/AttachMoney';
import NotifyIcon from '@material-ui/icons/NotificationsActive';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { 
  language,
  appConfig
} from 'config';
import { FirebaseContext } from 'common';
import { useStore } from 'react-redux';

function AppMenu() {
  const { api } = useContext(FirebaseContext);
  const {
    signOut,
    authUpdate
  } = api;

  const store = useStore();
  const auth = useSelector(state => state.auth);
  const modeList = store.getState().modedata;
  const [appMode, setMode] = useState(auth.info.profile.app_mode ? auth.info.profile.app_mode : appConfig.defaultMode);
  const dispatch = useDispatch();
  const LogOut = () => {
    dispatch(signOut());
  };

  const handleModeSelect = (event) => {
    setMode(event.target.value);
    let newmode = appConfig.defaultMode;
    for (let i = 0; i < modeList.length; i++) {
      if (modeList[i].name === event.target.value) {
        newmode = modeList[i].name;
      }
    }
    dispatch(authUpdate(auth.info,{
      app_mode: newmode
    }));
  };

  let isAdmin = auth.info && auth.info.profile && auth.info.profile.role === 'admin';
  let isManager = auth.info && auth.info.profile && auth.info.profile.role === 'manager';

  const WhiteTextTypography = withStyles({
    root: {
      color: "#FFFFFF",
      backgroundColor: "rgb(1, 87, 155)"
    }
  })(Typography);

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center', textColor: '#fff', backgroundColor: 'rgb(1, 87, 155)' }}>
        {modeList?
          <Select
            id="appMode"
            value={appMode}
            onChange={handleModeSelect}
          >
            {
              modeList.map((modes) =>
                <MenuItem key={modes.name} value={modes.name}>
                  <WhiteTextTypography variant="h6" noWrap>
                    {modes.name}
                  </WhiteTextTypography>
                </MenuItem>
              )
            }
          </Select>
        :null}
      </div>
      <Divider />
      <MenuList>
        {appConfig.staticPages?
        <MenuItem component={Link} to="/">
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <Typography variant="inherit">{language.menu_admin_home}</Typography>
        </MenuItem>
        :null}
        {isAdmin || isManager?
        <MenuItem component={Link} to="/dashboard">
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <Typography variant="inherit">{language.menu_admin_dashboard}</Typography>
        </MenuItem>
        :null}
        <MenuItem component={Link} to="/join">
          <ListItemIcon>
            <HowToRegIcon />
          </ListItemIcon>
          <Typography variant="inherit">{language.menu_admin_join}</Typography>
        </MenuItem>
        {isAdmin?
        <MenuItem component={Link} to="/revenue">
          <ListItemIcon>
            <TrendingUpIcon />
          </ListItemIcon>
          <Typography variant="inherit">{language.menu_admin_income}</Typography>
        </MenuItem>
        :null}
        <MenuItem component={Link} to="/salesorders">
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <Typography variant="inherit">{language.menu_admin_orders}</Typography>
        </MenuItem>
        {isAdmin || isManager?
        <MenuItem component={Link} to="/earnings">
          <ListItemIcon>
            <ListIcon />
          </ListItemIcon>
          <Typography variant="inherit">{language.menu_admin_earnings}</Typography>
        </MenuItem>
        :null}
        {isAdmin?
        <MenuItem component={Link} to="/payouts">
          <ListItemIcon>
            <MoneyIcon />
          </ListItemIcon>
          <Typography variant="inherit">{language.menu_admin_payouts}</Typography>
        </MenuItem>
        :null}
        {isAdmin?
        <MenuItem component={Link} to="/subscribers">
          <ListItemIcon>
            <ShoppingCartIcon />
          </ListItemIcon>
          <Typography variant="inherit">{language.menu_admin_subscribers}</Typography>
        </MenuItem>
        :null}
        {isAdmin || isManager?
        <MenuItem component={Link} to="/vendors">
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <Typography variant="inherit">{language.menu_admin_vendors}</Typography>
        </MenuItem>
        :null}
        {isAdmin || isManager?
          <MenuItem component={Link} to="/assignment">
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <Typography variant="inherit">{language.menu_admin_booking}</Typography>
          </MenuItem>
        :null}
        {isAdmin?
        <MenuItem component={Link} to="/credit">
          <ListItemIcon>
            <MoneyIcon />
          </ListItemIcon>
          <Typography variant="inherit">{language.menu_admin_credit}</Typography>
        </MenuItem>
        :null}
        {isAdmin?
        <MenuItem component={Link} to="/vouchers">
          <ListItemIcon>
            <OfferIcon />
          </ListItemIcon>
          <Typography variant="inherit">{language.menu_admin_vouchers}</Typography>
        </MenuItem>
        :null}
        {isAdmin?
        <MenuItem component={Link} to="/managers">
          <ListItemIcon>
            <SupervisorAccountIcon />
          </ListItemIcon>
          <Typography variant="inherit">{language.menu_admin_managers}</Typography>
        </MenuItem>
        :null}
        {isAdmin?
        <MenuItem component={Link} to="/messaging">
          <ListItemIcon>
            <NotifyIcon />
          </ListItemIcon>
          <Typography variant="inherit">{language.menu_admin_messages}</Typography>
        </MenuItem>
        :null}
        {isAdmin?
        <MenuItem component={Link} to="/modes">
          <ListItemIcon>
            <DeveloperModeIcon />
          </ListItemIcon>
          <Typography variant="inherit">{language.menu_admin_appmodes}</Typography>
        </MenuItem>
        :null}
        {isAdmin || isManager?
        <MenuItem component={Link} to="/products">
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <Typography variant="inherit">{language.menu_admin_products}</Typography>
        </MenuItem>
        :null}
        {isAdmin?
        <MenuItem component={Link} to="/metadata">
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <Typography variant="inherit">{language.menu_admin_metadata}</Typography>
        </MenuItem>
        :null}
        {isAdmin?
        <MenuItem component={Link} to="/settings">
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <Typography variant="inherit">{language.menu_admin_advanced}</Typography>
        </MenuItem>
        :null}
        <MenuItem component={Link} to="/profile">
          <ListItemIcon>
            <PersonOutlineIcon />
          </ListItemIcon>
          <Typography variant="inherit">{language.menu_admin_profile}</Typography>
        </MenuItem>
        <MenuItem onClick={LogOut}>
          <ListItemIcon>
            <ExitIcon />
          </ListItemIcon>
          <Typography variant="inherit">{language.menu_admin_logout}</Typography>
        </MenuItem>
      </MenuList>
    </div>
  );
}

export default AppMenu;