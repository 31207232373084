import { mainConfig }  from './mainconfig';
import { appConfig } from './appConfig';

import { 
    language,
    dateStyle
} from './language';

import { 
    countries,
    defaultCountry
} from './countries';

const appDetails = mainConfig.appDetails;
const firebaseConf = mainConfig.firebaseConf;
const googleMapkey = mainConfig.googleMapkey;
const facebookAppId = mainConfig.facebookAppId;
const mainUrl = "cloudfunctions.net";
const cloudFunctions = `https://us-central1-${firebaseConf.projectId}.${mainUrl}`;

export {
    appDetails,
    language,
    firebaseConf,
    googleMapkey,
    dateStyle,
    facebookAppId,
    cloudFunctions,
    appConfig,
    countries,
    defaultCountry
}