import React,{ useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
import CircularLoading from "../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import { language } from 'config';
import { FirebaseContext } from 'common';

const Payouts = () => {
  const { api } = useContext(FirebaseContext);
  const {
    payoutEmpty
  } = api;
  const dispatch = useDispatch();
  const columns =  [
      { title: 'TransID', field: 'id', editable: 'never' },
      { title: language.report_request_date, field: 'date', editable: 'never' },
      { title: language.vendor_name,field: 'vendor', editable: 'never'},
      { title: language.received_amount, field: 'amount', editable: 'never' },
      { title: language.report_processed, field: 'processed', type: 'boolean', editable: 'never'},  
      { title: language.payment_date, field: 'processed_date', editable: 'never'}, 
      { title: language.subscriber_bank_name, field: 'bank_name', editable: 'never' },
      { title: language.subscriber_bank_code, field: 'bank_code', editable: 'never'},
      { title: language.subscriber_bank_account, field: 'bank_account', editable: 'never' }, 
  ];
  const [data, setData] = useState([]);
  const withdrawdata = useSelector(state => state.payoutState);

  useEffect(()=>{
        if(withdrawdata.payouts){
            setData(withdrawdata.payouts);
        }else{
          setData([]);
        }
  },[withdrawdata.payouts]);
  
  return (
    withdrawdata.loading? <CircularLoading/>:
    <MaterialTable
      title={language.title_view_payouts}
      columns={columns}
      data={data}
      options={{
        exportButton: true,
        headerStyle: {
          backgroundColor: '#ee2324',
          color: '#FFF'
        }
      }}
      actions={[
        rowData => ({
          icon: 'check',
          tooltip: language.input_payout_request,
          disabled: rowData.processed,
          onClick: (event, rowData) => {
            dispatch(payoutEmpty(rowData));
          }         
        }),
      ]}
    />
  );
}

export default Payouts;