import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import {
  FormControl,
  Paper,
  Select,
  MenuItem,
  TextField
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from '@material-ui/pickers';
import GoogleMapsAutoComplete from '../components/GoogleMapsAutoComplete';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import styles from "assets/jss/material-kit-react/views/providerPage.js";
import Products from "./Sections/Products.js";
import { useSelector, useDispatch } from "react-redux";
import AlertDialog from '../components/AlertDialog';
import CountrySelect from '../components/CountrySelect';
import {language, defaultCountry} from 'config';
import { FirebaseContext } from 'common';

const useStyles = makeStyles(styles);

function getSteps() {
  return ['Step 1', 'Step 2', 'Step 3', 'Step 4', 'Get Paid'];
}

function getStepContent(stepIndex) {
  switch (stepIndex) {
    case 0:
      return language.join_step_one;
    case 1:
        return language.join_step_two;
    case 2:
      return language.join_step_three;  
    case 3:
      return language.join_step_four;  
    case 4:
      return language.join_step_five;  
    default:
      return 'Unknown Index';
  }
}

export default function Join(props) {

  const { api } = useContext(FirebaseContext);
  const {
    subscriberEdit,
    authReferer
  } = api;

  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  const classes = useStyles();
  const [advert, setAdvert] = useState(false);
  const [alert, setAlert] = useState({open:false,msg:''});
  const [bank, setBank] = useState(language.input_select_bank);
  const [brand, setBrand] = useState(language.input_brand);
  const [country, setCountry] = useState(defaultCountry);
  const [location, setLocation] = useState(null);
  const [number, setNumber] = useState(auth.info.phoneNumber);
  const [officeClose, setOfficeClose] = useState(new Date('2021-05-01T17:00:00'));
  const [officeOpen, setOfficeOpen] = useState(new Date('2021-05-01T09:00:00'));
  const [product, setProduct] = useState(language.input_select_product);
  const [activeStep, setActiveStep] = useState(0);
  const [travel, setTravel] = useState(0);
  const products = useSelector(state => state.productState.product_type);
  const steps = getSteps();

  const [data, setData] = useState({
    account_approved: false,
    account_balance: auth.info ? auth.info.profile.account_balance :'',
    account_created: auth.info ? auth.info.profile.account_created :'',
    address: auth.info ? auth.info.profile.address :'',
    app_mode: auth.info ? auth.info.profile.app_mode :'',
    bank_account: auth.info ? auth.info.profile.bank_account :'',
    bank_code: auth.info ? auth.info.profile.bank_code :'',
    bank_name: auth.info ? auth.info.profile.bank_name :'',
    email: auth.info ? auth.info.profile.email :'',
    first_name: auth.info ? auth.info.profile.first_name :'',
    last_name: auth.info ? auth.info.profile.last_name :'',
    mobile: auth.info ? auth.info.profile.mobile :'',
    product_name: auth.info ? auth.info.profile.product_name :'',
    product_brand: auth.info ? auth.info.profile.product_brand :'',
    product_description: auth.info ? auth.info.profile.product_description :'',
    product_model: auth.info ? auth.info.profile.product_model :'',
    product_number: auth.info ? auth.info.profile.product_number :'',
    product_suggestion: auth.info.profile.product_suggestion ? auth.info.profile.product_suggestion :'',
    referral_code: auth.info ? auth.info.profile.referral_code :'',
    referrer_code: auth.info ? auth.info.profile.referrer_code :'',
    referrer_id: auth.info ? auth.info.profile.referrer_id :'',
    role: 'vendor',
    token: auth.info ? auth.info.profile.token :'',
    transactions: auth.info ? auth.info.profile.transactions :''
  });

  const updateData = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  }

  const handleOfficeOpen = (date) => {
    setOfficeOpen(date);
    let open = officeOpen.getHours() + ':' + officeOpen.getMinutes() + '-' + officeClose.getHours() + ':' + officeClose.getMinutes();
    setData({ ...data, product_model: open})
  };

  const handleOfficeClose = (date) => {
    setOfficeClose(date);
    let close = officeOpen.getHours() + ':' + officeOpen.getMinutes() + '-' + officeClose.getHours() + ':' + officeClose.getMinutes();
    setData({ ...data, product_model: close})
  };

  const handleBankSelect = (event) => {
    setBank(event.target.value);
    setData({
      ...data, [event.target.name]: event.target.value,
    });
  };

  const handleProductSelect = (event) => {
    setProduct(event.target.value);
    setData({...data, [event.target.name]: event.target.value,
    });

    products.forEach(product => {
      if (product.name === event.target.value) {
        setAdvert(product.promotion);
        setTravel(product.travel_rate);
        setData({
          ...data, product_description: product.description,
          product_name: product.name
        });
      }
    });
  };

  const handleBrandSelect = (event) => {
    setBrand(event.target.value);
    setData({ ...data, product_brand: event.target.value})
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    switch (activeStep) {
      case 0:
        if (product === language.input_select_product && data.product_suggestion.length < 5) {
          setAlert({ open: true, msg: language.error_none_selected });
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
          return;
        }
        if (!location || location.length < 10) {
          setAlert({ open: true, msg: language.error_location_none });
          setActiveStep((prevActiveStep) => prevActiveStep - 1);         
        }
        break;
      case 1:
        if (data.first_name === undefined || data.last_name === undefined || data.email === undefined || data.mobile === undefined) {
          setAlert({ open: true, msg: language.error_try_again });
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
          return; 
        }
        if (data.first_name.length < 2 || data.last_name.length < 3) {
          setAlert({ open: true, msg: language.error_name });
          setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
      break;
      case 2:
        if (product === 'Family Counselor' || product === 'Legal Advisor'){
          data.product_model = officeOpen.getHours() + ':' + officeOpen.getMinutes() + '-' + officeClose.getHours() + ':' + officeClose.getMinutes();
        }
        if (data.product_brand === undefined || data.product_model === undefined || data.product_number === undefined) {
          setAlert({ open: true, msg: language.error_try_again });
          setActiveStep((prevActiveStep) => prevActiveStep - 1); 
        } else {          
          if (data.product_brand.length < 3 || data.product_model.length < 3 || data.product_number.length < 7) {
            setAlert({ open: true, msg: language.error_try_again });
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
            return;
          }
          if (product === 'Family Counselor' || product === 'Legal Advisor'){
            let formattedNum = data.product_number.replace(/ /g, '');
            formattedNum = formattedNum.replace(/-/g, '');
            formattedNum = formattedNum.replace(/[^0-9]/g, '');
            formattedNum = formattedNum.substr(-9);
            if (formattedNum.length<9) {
              setAlert({ open: true, msg: language.error_mobile });
              setActiveStep((prevActiveStep) => prevActiveStep - 1);
              return;
            }
            data.product_number = "+" + country.phone + formattedNum;
            let domainValid = true;
            const regx1 = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/
            domainValid = regx1.test(data.product_brand);
            if (!domainValid) {
              setAlert({ open: true, msg: language.error_url});
              setActiveStep((prevActiveStep) => prevActiveStep - 1);
            }
          }
        }
      break;
      case 3:
        if (data.referrer_code) {
          // TODO update current user balance
          // data.account_balance = some bonus
          // because on submit authReferer bonus below will be overwritten
          new Promise((resolve,reject) => {
            setTimeout(() => {
              authReferer(data.referrer_code).then((referralInfo) => {
                if (referralInfo.uid) {
                  setAlert({ open: true, msg: language.payment_bonus_allocated });
                  resolve();
                }
                else{
                  setAlert({ open: true, msg: language.error_no_bonus });
                  reject();
                }
              });
            }, 600);
          })             
        }
        setData({ ...data, referrer_code: data.referrer_code ? data.referrer_code : '' })
      break;
      default:
        return;
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSubmit = () => {
    if (data.bank_name === undefined || data.bank_code === undefined || data.bank_account === undefined) {
      setAlert({ open: true, msg: language.error_try_again });
      return;
    } else {          
      data.bank_code = data.bank_code.replace(/[^0-9]/g, '');
      data.bank_account = data.bank_account.replace(/[^0-9]/g, '');
      if (data.bank_code.length < 4 || data.bank_account.length < 6 ) {
        setAlert({ open: true, msg: language.error_bank_details });
        return;
      }
    }

    if (auth.info.uid) {
      new Promise(resolve => {
        setTimeout(() => {
          resolve();
          dispatch(subscriberEdit(auth.info.uid,data));
        }, 600);
      })
    } else {
      setAlert({ open: true, msg: language.error_insufficient });
      return;
    }
    data.account_approved = true;
    setAlert({open:true,msg:language.output_applied})
  }

  const alertClose = (event) => {
    event.preventDefault();
    setAlert({open:false,msg:'closing'})
  };

  return (
    <div>
      {products?
        <div className={classes.container}>
          <GridContainer spacing={2}>
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <h1 className={classes.title}>{language.title_join}</h1>
            </GridItem>
          </GridContainer>
          <Stepper style={{ padding: '1px'}} activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div>
                <Typography className={classes.instructions}>All steps completed</Typography>
                <Button onClick={handleReset}>Reset</Button>
              </div>
            ) : (
              <div>
              <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                {activeStep === 0 ?
                  <div>           
                   <GridContainer spacing={2}>
                      <GridItem xs={12} sm={6}>
                        <CountrySelect
                            value={country}
                            onChange={
                              (object, value) => {
                                setCountry(value);
                                let formattedNum = number.replace(/ /g, '');
                                formattedNum = formattedNum.replace(/-/g, '');
                                formattedNum = "+" + value.phone + formattedNum.substr(-9);
                                setData({ ...data, mobile: formattedNum })
                              }
                            }
                            style={{ height: 75,
                              borderRadius: 4,
                              position: 'relative',
                              backgroundColor:  "#E8E8E8",
                              fontSize: 14,
                              marginTop: '20px',
                              padding: '10px 2px 10px 2px',
                              color:"#000"}}
                        />
                      </GridItem>
                    </GridContainer>                  
                    <GridContainer spacing={2}>
                      {products ?
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                          <FormControl style={{ width: '100%'}}>
                            <Select
                              id="product"
                              value={product}
                              onChange={handleProductSelect}
                              className={product === language.input_select_product ? classes.inputdimmed : classes.input}
                              name="product"
                            >
                              <MenuItem value={language.input_select_product} key={language.input_select_product}>
                                {language.input_select_product}
                              </MenuItem>
                              {
                                products.map((product) =>
                                  <MenuItem key={product.name} value={product.name}>
                                    <img src={product.image} className={classes.productPic} alt="product_image" />{product.name}
                                  </MenuItem>
                                )
                              }
                            </Select>
                          </FormControl>
                        </GridItem>
                      :
                        <GridItem xs={12} sm={12} md={6} lg={6}>
                          <Paper >
                            No data
                          </Paper>
                        </GridItem>
                      } 
                      <GridItem xs={6} sm={6} md={3} lg={3}>
                        <TextField
                          onChange={updateData}
                          style={{marginTop: 0}}
                          className={classes.input}
                          margin="normal"
                          required
                          fullWidth
                          id="product_suggestion"
                          helperText={language.input_suggest}
                          name="product_suggestion" />
                      </GridItem>
                    </GridContainer>
                    {advert ?
                        <GridContainer spacing={2}>
                          <GridItem xs={12} sm={12} md={6} lg={6}>
                            <Paper style={{paddingLeft:'5px'}}>
                              {advert}
                            </Paper>
                          </GridItem>
                        </GridContainer>
                      :null} 
                    <GridContainer spacing={2}>
                      <GridItem xs={12} sm={12} md={6} lg={6}>
                        <Paper >
                          <GoogleMapsAutoComplete 
                            placeholder={language.input_location}
                            variant={"filled"}
                            value={location}
                            onChange={
                              (value) => {
                                setLocation(value.description);
                                setData({ ...data, address: value.description });
                              }
                            }
                          />
                        </Paper>
                      </GridItem>
                    </GridContainer>
                  </div>
                :null}                
                {activeStep === 1 ?
                  <div>  
                    <GridContainer spacing={2}>
                      <GridItem xs={6} sm={6} md={3} lg={3}>
                        <TextField
                          autoFocus 
                          onChange={updateData}
                          className={classes.input}
                          margin="normal"
                          required
                          fullWidth
                          id="first_name"
                          helperText={language.subscriber_first_name}
                          name="first_name"                         
                          defaultValue={auth.info ? auth.info.profile.first_name : data.first_name} />
                      </GridItem>
                      <GridItem xs={6} sm={6} md={3} lg={3}>
                        <TextField
                          onChange={updateData}
                          className={classes.input}
                          margin="normal"
                          required
                          fullWidth
                          id="last_name"
                          helperText={language.subscriber_last_name}
                          name="last_name"
                          defaultValue={auth.info ? auth.info.profile.last_name : data.last_name} />
                      </GridItem>
                    </GridContainer>                    
                    <GridContainer spacing={2}>
                      <GridItem xs={6} sm={6} md={3} lg={3}>
                        <TextField
                          onChange={updateData}
                          className={classes.input}
                          margin="normal"
                          required
                          fullWidth
                          id="email"
                          helperText={language.subscriber_email}
                          name="email"
                          disabled={data.email ? true : false}
                          defaultValue={auth.info ? auth.info.email : data.email} />
                      </GridItem>
                      <GridItem xs={6} sm={6} md={3} lg={3}>
                      <TextField
                            className={classes.input}
                            margin="normal"
                            required
                            fullWidth
                            id="mobile"
                            helperText={language.input_mobile_format}
                            name="mobile"
                            disabled={data.mobile ? true : false}
                            defaultValue={auth.info ? auth.info.phoneNumber : data.mobile}
                            onChange={
                              (event) => {
                                setNumber(event.target.value)
                                let formattedNum = event.target.value.replace(/ /g, '');
                                formattedNum = formattedNum.replace(/-/g, '');
                                formattedNum = "+" + country.phone + formattedNum.substr(-9);
                                setData({ ...data, mobile: formattedNum });
                              }
                            }
                            value={number} />
                      </GridItem> 
                    </GridContainer> 
                  </div>
                :null}
                {activeStep === 2 ?
                  <div>
                    {travel < 1 ?  
                      <GridContainer spacing={2}>
                        <GridItem xs={6} sm={6} md={2} lg={2}>
                          <TextField
                            onChange={updateData}
                            className={classes.input}
                            margin="normal"
                            required
                            fullWidth
                            id="product_brand"
                            helperText={language.input_social_media}
                            name="product_brand" 
                            defaultValue={data.product_brand ? data.product_brand : ''} />
                        </GridItem>
                        <GridItem xs={6} sm={6} md={2} lg={2}>
                          <TextField
                            onChange={updateData}
                            className={classes.input}
                            margin="normal"
                            required
                            fullWidth
                            id="product_number"
                            helperText={language.input_landline}
                            name="product_number"
                            defaultValue={data.product_number ? data.product_number : ''} />
                        </GridItem>
                        <br />
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <GridItem xs={6} sm={6} md={2} lg={2}>     
                                <KeyboardTimePicker
                                  margin="normal"
                                  id="open"
                                  label={language.input_office_open}
                                  value={officeOpen}
                                  onChange={handleOfficeOpen}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                  }}
                                />
                          </GridItem>
                          <GridItem xs={6} sm={6} md={2} lg={2}>     
                                <KeyboardTimePicker
                                  margin="normal"
                                  id="close"
                                  label={language.input_office_close}
                                  value={officeClose}
                                  onChange={handleOfficeClose}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change time',
                                  }}
                                />
                          </GridItem>
                        </MuiPickersUtilsProvider>
                      </GridContainer>                 
                  :   
                      <div className={classes.spacing}>
                        <GridContainer spacing={2}>
                          <GridItem xs={6} sm={6} md={2} lg={2}>
                            <FormControl style={{ width: '100%' }}>
                              <Select
                                  id="brand"
                                  value={brand}
                                  onChange={handleBrandSelect}
                                  className={classes.input}
                                  inputProps={{ 'aria-label': 'Without label' }}
                                  name="brand"
                                >
                                <MenuItem value={language.input_brand} key={language.input_brand}>
                                  {language.input_brand}
                                </MenuItem>
                                <MenuItem key={"Acura"} value={"Acura"}>Acura</MenuItem>
                                <MenuItem key={"Alfa"} value={"Alfa"}>Alfa</MenuItem>
                                <MenuItem key={"Audi"} value={"Audi"}>Audi</MenuItem>
                                <MenuItem key={"Bentley"} value={"Bentley"}>Bentley</MenuItem>
                                <MenuItem key={"BMW"} value={"BMW"}>BMW</MenuItem>
                                <MenuItem key={"Buick"} value={"Buick"}>Buick</MenuItem>
                                <MenuItem key={"Cadillac"} value={"Cadillac"}>Cadillac</MenuItem>
                                <MenuItem key={"Chevrolet"} value={"Chevrolet"}>Chevrolet</MenuItem>
                                <MenuItem key={"Citroen"} value={"Citroen"}>Citroen</MenuItem>
                                <MenuItem key={"Chrysler"} value={"Chrysler"}>Chrysler</MenuItem>
                                <MenuItem key={"Datsun"} value={"Datsun"}>Datsun</MenuItem>
                                <MenuItem key={"Dodge"} value={"Dodge"}>Dodge</MenuItem>
                                <MenuItem key={"Ferrari"} value={"Ferrari"}>Ferrari</MenuItem>
                                <MenuItem key={"Fiat"} value={"Fiat"}>Fiat</MenuItem>
                                <MenuItem key={"Ford"} value={"Ford"}>Ford</MenuItem>
                                <MenuItem key={"Genesis"} value={"Genesis"}>Genesis</MenuItem>
                                <MenuItem key={"GMC"} value={"GMC"}>GMC</MenuItem>
                                <MenuItem key={"GWM"} value={"GWM"}>GWM</MenuItem>
                                <MenuItem key={"Haval"} value={"Haval"}>Haval</MenuItem>
                                <MenuItem key={"Honda"} value={"Honda"}>Honda</MenuItem>
                                <MenuItem key={"Hyundai"} value={"Hyundai"}>Hyundai</MenuItem>
                                <MenuItem key={"Infinity"} value={"Infinity"}>Infinity</MenuItem>
                                <MenuItem key={"Isuzu"} value={"Isuzu"}>Isuzu</MenuItem>
                                <MenuItem key={"Jaguar"} value={"Jaguar"}>Jaguar</MenuItem>
                                <MenuItem key={"Jeep"} value={"Jeep"}>Jeep</MenuItem>
                                <MenuItem key={"Kia"} value={"Kia"}>Kia</MenuItem>
                                <MenuItem key={"Landrover"} value={"Landrover"}>Landrover</MenuItem>
                                <MenuItem key={"Lexus"} value={"Lexus"}>Lexus</MenuItem>
                                <MenuItem key={"Lincoln"} value={"Lincoln"}>Lincoln</MenuItem>
                                <MenuItem key={"Mahindra"} value={"Mahindra"}>Mahindra</MenuItem>
                                <MenuItem key={"Maserati"} value={"Maserati"}>Maserati</MenuItem>
                                <MenuItem key={"Mazda"} value={"Mazda"}>Mazda</MenuItem>
                                <MenuItem key={"Mercedez"} value={"Mercedez"}>Mercedez</MenuItem>
                                <MenuItem key={"Mercury"} value={"Mercury"}>Mercury</MenuItem>
                                <MenuItem key={"Mini"} value={"Mini"}>Mini</MenuItem>
                                <MenuItem key={"Mitsubishi"} value={"Mitsubishi"}>Mitsubishi</MenuItem>
                                <MenuItem key={"Nissan"} value={"Nissan"}>Nissan</MenuItem>
                                <MenuItem key={"Pajero"} value={"Pajero"}>Pajero</MenuItem>
                                <MenuItem key={"Peugeot"} value={"Peugeot"}>Peugeot</MenuItem>
                                <MenuItem key={"Polestar"} value={"Polestar"}>Polestar</MenuItem>
                                <MenuItem key={"Pontiac"} value={"Pontiac"}>Pontiac</MenuItem>
                                <MenuItem key={"Porsche"} value={"Porsche"}>Porsche</MenuItem>
                                <MenuItem key={"Ram"} value={"Ram"}>Ram</MenuItem>
                                <MenuItem key={"Renault"} value={"Renault"}>Renault</MenuItem>
                                <MenuItem key={"Saab"} value={"Saab"}>Saab</MenuItem>
                                <MenuItem key={"Saturn"} value={"Saturn"}>Saturn</MenuItem>
                                <MenuItem key={"Scion"} value={"Scion"}>Scion</MenuItem>
                                <MenuItem key={"Smart"} value={"Smart"}>Smart</MenuItem>
                                <MenuItem key={"Subaru"} value={"Subaru"}>Subaru</MenuItem>
                                <MenuItem key={"Suzuki"} value={"Suzuki"}>Suzuki</MenuItem>
                                <MenuItem key={"Tesla"} value={"Tesla"}>Tesla</MenuItem>
                                <MenuItem key={"Toyota"} value={"Toyota"}>Toyota</MenuItem>
                                <MenuItem key={"Volkswagen"} value={"Volkswagen"}>Volkswagen</MenuItem>
                                <MenuItem key={"Volvo"} value={"Volvo"}>Volvo</MenuItem>
                                </Select>
                            </FormControl>
                          </GridItem>
                          <GridItem xs={6} sm={6} md={2} lg={2}>
                            <TextField
                              onChange={updateData}
                              style={{marginTop: 0}}
                              className={classes.input}
                              margin="normal"
                              required
                              fullWidth
                              id="product_model"
                              helperText={language.input_model}
                              name="product_model"
                              defaultValue={data.product_model ? data.product_model : ''} />
                          </GridItem>
                          <GridItem xs={6} sm={6} md={2} lg={2}>
                            <TextField
                              onChange={updateData}
                              style={{marginTop: 0}}
                              inputProps={{ style: { textTransform: "uppercase" } }}
                              className={classes.input}
                              margin="normal"
                              required
                              fullWidth
                              id="product_number"
                              helperText={language.input_registration}
                              name="product_number" 
                              defaultValue={data.product_number ? data.product_number : ''} />
                          </GridItem>                   
                        </GridContainer>
                      </div>                 
                    }
                  </div>
                :null}       
               {activeStep === 3 ?
                    <GridContainer>
                        <GridItem xs={6} sm={6} md={3} lg={3}>
                          <TextField
                            onChange={updateData}
                            className={classes.input}
                            margin="normal"
                            required
                            fullWidth
                            id="referrer_code"
                            helperText={language.input_referrer_code}
                            name="referrer_code"
                            defaultValue={data.referrer_code ? data.referrer_code : ''} />
                        </GridItem>
                      </GridContainer>        
                :null}
                {activeStep === 4 ?
                  <div className={classes.spacing}>
                    <GridContainer spacing={2}>
                      <GridItem xs={6} sm={6} md={2} lg={2}>
                        <FormControl style={{ width: '100%', margin: 'normal' }}>
                          <Select
                            onChange={handleBankSelect}                         
                            id="bank_name"
                            value={bank}
                            className={classes.input}
                            inputProps={{ 'aria-label': 'Without label' }}
                            name="bank_name"
                          >
                            <MenuItem value={language.input_select_bank} key={language.input_select_bank}>
                              {language.input_select_bank}
                            </MenuItem>
                            <MenuItem key={"ABSA Bank"} value={"ABSA"}>
                            ABSA Bank
                            </MenuItem>
                            <MenuItem key={"African Bank"} value={"African Bank"}>
                            African Bank
                            </MenuItem>
                            <MenuItem key={"Bidvest Bank"} value={"Bidvest Bank"}>
                            Bidvest Bank
                            </MenuItem>
                            <MenuItem key={"Capitec Bank Bank"} value={"Capitec Bank"}>
                            Capitec Bank Bank
                            </MenuItem>
                            <MenuItem key={"Discovery Bank"} value={"Discovery Bank"}>
                            Discovery Bank
                            </MenuItem>
                            <MenuItem key={"First National Bank"} value={"First National Bank"}>
                            First National Bank
                            </MenuItem>
                            <MenuItem key={"Grindrod Bank"} value={"Grindrod Bank"}>
                            Grindrod Bank
                            </MenuItem>
                            <MenuItem key={"Imperial Bank"} value={"Imperial Bank"}>
                            Imperial Bank
                            </MenuItem>
                            <MenuItem key={"Investec Bank"} value={"Investec Bank"}>
                            Investec Bank
                            </MenuItem>
                            <MenuItem key={"Nedbank"} value={"Nedbank"}>
                            Nedbank
                            </MenuItem>
                            <MenuItem key={"Sasfin Bank"} value={"Sasfin Bank"}>
                            Sasfin Bank
                            </MenuItem>
                            <MenuItem key={"Standard Bank"} value={"Standard Bank"}>
                            Standard Bank
                            </MenuItem>
                            <MenuItem key={"Ubank"} value={"Ubank"}>
                            Ubank
                            </MenuItem>
                            <MenuItem key={"TymeBank"} value={"TymeBank"}>
                            TymeBank
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={6} sm={6} md={2} lg={2}>
                        <TextField
                          onChange={updateData}
                          style={{marginTop: 0}}
                          className={classes.input}
                          margin="normal"
                          required
                          fullWidth
                          id="bank_code"
                          helperText={language.subscriber_bank_code}
                          name="bank_code"
                          defaultValue={data.bank_code ? data.bank_code : ''} />
                      </GridItem>
                      <GridItem xs={6} sm={6} md={2} lg={2}>
                        <TextField
                          onChange={updateData}
                          style={{marginTop: 0}}
                          className={classes.input}
                          margin="normal"
                          required
                          fullWidth
                          id="bank_account"
                          helperText={language.subscriber_bank_account}
                          name="bank_account"
                          defaultValue={data.bank_account ? data.bank_account : ''} />
                      </GridItem>
                    </GridContainer>
                    <GridContainer spacing={2}>
                      {data.account_approved === false ?
                        <GridItem xs={6} sm={6} md={6} lg={6}>
                          <Button
                            id="input_apply"
                            color="success"
                            size="lg"
                            rel="noopener noreferrer"
                            className={classes.items}
                            onClick={handleSubmit}
                          >
                            {language.input_apply}
                          </Button>
                        </GridItem>
                      :null}
                    </GridContainer>
                  </div>
                :null}
                <div className={classes.stepper}>
                  {data.account_approved === false ?
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.backButton}
                    >
                      Back
                    </Button>
                  :null}
                  {activeStep < 4 ?
                    <Button variant="contained" color="primary" onClick={handleNext}>
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                  :null}
                </div>
              </div>
            )}
          </div>
        </div>
      : 
        <div className={classes.container}>
          <GridContainer spacing={2}>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <br />
              <h1 className={classes.title}>{language.title_slogan}</h1>
            </GridItem>
          </GridContainer>
        </div>
      }
      <div>
        <div className={classes.container}>
          <Products />
        </div>
      </div>
      <AlertDialog open={alert.open} onClose={alertClose}>{alert.msg}</AlertDialog>
    </div>
  );
}
