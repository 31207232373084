import React, { useState, useEffect, useContext } from 'react';
import {
    Grid,
    Button,
    Typography,
    TextField
} from '@material-ui/core';
import { useSelector, useDispatch } from "react-redux";
import AlertDialog from '../components/AlertDialog';
import { language } from 'config';
import { makeStyles } from '@material-ui/core/styles';
import UsersCombo from '../components/UsersCombo';
import { FirebaseContext } from 'common';

const useStyles = makeStyles(theme => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    container: {
        zIndex: "12",
        color: "#FFFFFF",
        alignContent: 'center'
    },
    title: {
        color: "#000",
    },
    gridcontainer: {
        alignContent: 'center'
    },
    items: {
        margin: 0,
        width: '100%'
    },
    input: {
        fontSize: 18,
        color: "#000"
    },
    inputdimmed: {
        fontSize: 18,
        color: "#737373"
    },
    carphoto: {
        height: '18px',
        marginRight: '10px'
    },
    buttonStyle: {
        margin: 0,
        width: '100%',
        height: '100%'
    }
}));

export default function Credit(props) {
    const { api } = useContext(FirebaseContext);
    const {
        paymentCredit
    } = api;
    const dispatch = useDispatch();
    const classes = useStyles();
    const subscriberData = useSelector(state => state.subscriberState);
    const [subscribers, setUsers] = useState(null);
    const [alert, setAlert] = useState({ open: false, msg: '' });
    const [userCombo, setUserCombo] = useState(null);
    const [amount, setAmount] = useState(0);

    useEffect(() => {
        if (subscriberData.subscribers) {
            let arr = [];
            for (let i = 0; i < subscriberData.subscribers.length; i++) {
                let subscriber = subscriberData.subscribers[i];
                arr.push({
                    'first_name': subscriber.first_name,
                    'last_name': subscriber.last_name,
                    'mobile': subscriber.mobile,
                    'email': subscriber.email,
                    'uid': subscriber.id,
                    'sort': subscriber.first_name + ' ' + subscriber.last_name + ' (' + subscriber.mobile + ') ' + subscriber.email,
                    'token': subscriber.token
                });
            }
            setUsers(arr);
        }
    }, [subscriberData.subscribers]);

    const handlePayment = () => {
        if(userCombo && userCombo.uid && amount> 0){
            dispatch(paymentCredit(userCombo.uid,amount));
            setAlert({ open:true, msg: language.output_credited});
        }else{
            setAlert({ open:true, msg: language.error_insufficient});
        }
    }

    const alertClose = (e) => {
        e.preventDefault();
        setAlert({ open: false, msg: '' })
    };

    return (
        <div className={classes.container}>
            <Grid item xs={12} sm={12} md={8} lg={8}>
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        <Typography component="h1" variant="h5" className={classes.title}>
                            {language.title_credit}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {subscribers ?
                            <UsersCombo
                                className={classes.items}
                                placeholder={language.input_select_subscriber}
                                subscribers={subscribers}
                                value={userCombo}
                                onChange={(event, newValue) => {
                                    setUserCombo(newValue);
                                }}
                            />
                            : null}
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <TextField
                            id="datetime-local"
                            label={language.credit}
                            type="text"
                            variant="outlined"
                            fullWidth
                            className={classes.commonInputStyle}
                            InputProps={{
                                className: classes.input
                            }}
                            value={amount}
                            onChange={event => {
                                const { value } = event.target;
                                setAmount(value === '' || value === null || value === undefined? 0:parseFloat(value));
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} >
                        <Button
                            size="large"
                            onClick={handlePayment}
                            variant="contained"
                            color="primary"
                            className={classes.buttonStyle}
                        >
                            {language.input_credit}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <AlertDialog open={alert.open} onClose={alertClose}>{alert.msg}</AlertDialog>
        </div>
    );
}