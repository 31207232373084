import React, { useState, useEffect, useContext } from 'react';
import CircularLoading from "../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import Button from "components/CustomButtons/Button.js";
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import {language} from "config";
import AlertDialog from '../components/AlertDialog';
import { FirebaseContext } from 'common';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    width: 192,
    height: 192
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Profile = () => {
  const { api } = useContext(FirebaseContext);
  const {
    authUpdate
  } = api;
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [alert, setAlert] = useState({open:false,msg:''});

  const [data, setData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    mobile: '',
    login_method: null,
    role: ''
  });

  useEffect(() => {
    if (auth.info && auth.info.profile) {
      setData({
        first_name: !auth.info.profile.first_name || auth.info.profile.first_name === ' '? '' : auth.info.profile.first_name,
        last_name: !auth.info.profile.last_name || auth.info.profile.last_name === ' '? '' : auth.info.profile.last_name,
        email: !auth.info.profile.email || auth.info.profile.email === ' '? '' : auth.info.profile.email,
        mobile: !auth.info.profile.mobile || auth.info.profile.mobile === ' '? '' : auth.info.profile.mobile,
        login_method: auth.info.profile.login_method?'social':'email',
        role: auth.info.profile.role,
        uid: auth.info.uid
      });
    }
  }, [auth.info]);

  const updateData = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    //eslint-disable-next-line
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (regex.test(data.email)) {
      dispatch(authUpdate(auth.info,{
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        mobile: data.mobile
      }));
      setAlert({open:true,msg:language.output_profile_updated})
    }else{
      setAlert({open:true,msg:language.error_email})
    }
  }

  const alertClose = (e) => {
    e.preventDefault();
    setAlert({open:false,msg:''})
  };

  return (
    auth.loading ? <CircularLoading /> :
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <form className={classes.form} onSubmit={handleSubmit}>
            <Typography component="h1" variant="h5">
              {language.title_profile}
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="first_name"
              label={language.subscriber_first_name}
              name="first_name"
              autoComplete="first_name"
              onChange={updateData}
              value={data.first_name}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="last_name"
              label={language.subscriber_last_name}
              name="last_name"
              autoComplete="last_name"
              onChange={updateData}
              value={data.last_name}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={language.subscriber_email}
              name="email"
              autoComplete="email"
              onChange={updateData}
              value={data.email}
              disabled={data.login_method==='email'?true:false}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="mobile"
              label={language.subscriber_mobile}
              name="mobile"
              autoComplete="mobile"
              onChange={updateData}
              value={data.mobile}
              disabled={data.login_method==='email'?true:false}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="role"
              label={language.subscriber_role}
              name="role"
              value={data.role}
              disabled={true}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="success"
              className={classes.submit}
            >
              {language.input_submit}
            </Button>
          </form>
        </div>
        <AlertDialog open={alert.open} onClose={alertClose}>{alert.msg}</AlertDialog>
      </Container>
  );
};

export default Profile;