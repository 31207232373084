import React, { useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import {
  appConfig,
  dateStyle,
  language
} from 'config';
import { FirebaseContext } from 'common';

export default function Vouchers() {
  const { api } = useContext(FirebaseContext);
  const {
    voucherEdit
  } = api;

  const columns = [
    { title: language.voucher_name, field: 'voucher_name' },
    { title: language.voucher_description, field: 'voucher_description' },
    { title: language.voucher_markup, field: 'voucher_markup',
      lookup: { flat: language.product_flat_rate, percentage: language.product_percentage_rate },
    },
    { title: language.voucher_value, field: 'voucher_value', type: 'numeric' },
    { title: language.voucher_max_redeem_limit, field: 'voucher_max_redeem_limit', type: 'numeric' },
    { title: language.voucher_order_value_limit, field: 'voucher_order_value_limit', type: 'numeric' },
    { title: language.voucher_expiry_date, field: 'voucher_expiry_date', render: rowData => rowData.voucher_expiry_date ? new Date(rowData.voucher_expiry_date).toLocaleDateString(dateStyle) : null },
    { title: language.voucher_issued, field: 'vouchers_issued', type: 'numeric' },
    { title: language.voucher_redeemed, field: 'vouchers_redeemed', editable: 'never' }
  ];

  const [data, setData] = useState([]);
  const voucherData = useSelector(state => state.voucherState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (voucherData.vouchers) {
      setData(voucherData.vouchers);
    } else {
      setData([]);
    }
  }, [voucherData.vouchers]);

  return (
    voucherData.loading ? <CircularLoading /> :
      <MaterialTable
        title={language.title_view_promos}
        columns={columns}
        data={data}
        options={{
          headerStyle: {
            backgroundColor: '#ee2324',
            color: '#FFF'
          }
        }}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve, reject) => {
              if (appConfig.productionMode) {
                setTimeout(() => {
                  resolve();
                  const tblData = data;
                  tblData.push(newData);
                  dispatch(voucherEdit(tblData));
                }, 600);
              } else {
                alert(language.demo_mode);
                reject();
              }
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              if (appConfig.productionMode) {
                setTimeout(() => {
                  resolve();
                  const tblData = data;
                  tblData[tblData.indexOf(oldData)] = newData;
                  dispatch(voucherEdit(tblData));
                }, 600);
              } else {
                alert(language.demo_mode);
                reject();
              }
            }),
          onRowDelete: oldData =>
            new Promise((resolve, reject) => {
              if (appConfig.productionMode) {
                setTimeout(() => {
                  resolve();
                  const tblData = data;
                  tblData.splice(tblData.indexOf(oldData), 1);
                  dispatch(voucherEdit(tblData));
                }, 600);
              } else {
                alert(language.demo_mode);
                reject();
              }
            }),
        }}
      />
  );
}