import React from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";
import "assets/scss/material-kit-react.scss?v=1.9.0";

import About from "./views/About";
import AuthLoading from './views/AuthLoading';
import Credit from "./views/Credit";
import Subscribers from './views/Subscribers';
import Dashboard from './views/Dashboard';
import Earnings from './views/Earnings';
import Revenue from './views/Revenue';
import Home from "./views/Home.js";
import Join from './views/Join';
import Login from "./views/Login.js";
import Managers from './views/Managers';
import Messaging from './views/Messaging';
import Meta from './views/Meta';
import Modes from './views/Modes';
import Profile from './views/Profile';
import Assignment from './views/Assignment';
import Payouts from './views/Payouts';
import Privacy from "./views/Privacy.js";
import Protected from './views/Protected';
import Products from './views/Products';
import Register from './views/Register';
import SalesOrders from './views/SalesOrders';
import Settings from './views/Settings';
import Vendors from './views/Vendors';
import Vouchers from './views/Vouchers';
import { Provider } from "react-redux";

import {
  store,
  FirebaseProvider
} from "common";

import { appConfig } from 'config';


var hist = createBrowserHistory();

// Component Routes
function App() {
  return (
    <Provider store={store}>
      <FirebaseProvider>
        <AuthLoading>
          <Router history={hist}>
            <Switch>
              <Protected exact component={SalesOrders} path="/salesorders" permit={"subscriber,admin,vendor,manager"} />
              <Protected exact component={Profile} path="/profile" permit={"subscriber,admin,vendor,manager"} />
              <Protected exact component={Dashboard} path="/dashboard" permit={"admin,manager"} />
              <Protected exact component={Products} path="/products" permit={"admin"} />
              <Protected exact component={Modes} path="/modes" permit={"admin"} />
              <Protected exact component={Meta} path="/metadata" permit={"admin"} />
              <Protected exact component={Assignment} path="/assignment" permit={"admin"} />
              <Protected exact component={Vouchers} path="/vouchers" permit={"admin"} />
              <Protected exact component={Subscribers} path="/subscribers" permit={"admin"} />
              <Protected exact component={Vendors} path="/vendors" permit={"admin,manager"} />
              <Protected exact component={Managers} path="/managers" permit={"admin"} />
              <Protected exact component={Earnings} path="/earnings" permit={"admin,manager"} />
              <Protected exact component={Messaging} path="/messaging" permit={"admin"} />
              <Protected exact component={Revenue} path="/revenue" permit={"admin"} />
              <Protected exact component={Credit} path="/credit" permit={"admin"} />
              <Protected exact component={Payouts} path="/payouts" permit={"admin"} />
              <Protected exact component={Settings} path="/settings" permit={"admin"} />
              <Protected exact component={Join} path="/join" permit={"subscriber,admin,vendor,manager"} />
              <Route path="/About" component={About} />
              <Route path="/privacy-policy" component={Privacy} />
              {appConfig.staticPages ?
                <Route path="/register" component={Register} />
                : null}
              {appConfig.staticPages ?
                <Route path="/login" component={Login} />
                : null}
              {appConfig.staticPages ?
                <Route path="/" component={Home} />
                :
                <Route path="/" component={Login} />
              }
            </Switch>
          </Router>
        </AuthLoading>
      </FirebaseProvider>
    </Provider>
  );
}

export default App;