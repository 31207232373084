import React, { useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import {
  appConfig,
  language
} from 'config';
import { FirebaseContext } from 'common';

export default function Products() {
  const { api } = useContext(FirebaseContext);
  const {
    productEdit
  } = api;
  const modedata = useSelector(state => state.modeState);
  const [mode, setMode] = useState({});

  useEffect(()=>{
    if(modedata.modes){
        let obj =  {};
        modedata.modes.map((appmode)=> obj[appmode.name]=appmode.name)
        setMode(obj);
    }
  },[modedata.modes]);


  // TODO use modes table for app_mode lookup below
  const columns = [
    { title: language.report_image, field: 'image', render: rowData => <img alt='service_icon' src={rowData.image} style={{ width: 50 }} /> },
    { title: language.mode_services, field: 'app_mode', lookup: mode},
    { title: language.product_type, field: 'name' },
    { title: language.product_base_price, field: 'base_price', type: 'numeric' },
    { title: language.product_travel_rate, field: 'travel_rate', type: 'numeric' },
    { title: language.product_hourly_rate, field: 'hourly_rate', type: 'numeric' },
    { title: language.product_min_price, field: 'min_price', type: 'numeric' },
    { title: language.product_service_fee, field: 'service_fee', type: 'numeric' },
    { title: language.product_markup, field: 'markup',
      lookup: { flat: language.product_flat_rate, percentage: language.product_percentage_rate },
    },
    { title: language.product_description, field: 'description' },
    { title: language.product_promotion, field: 'promotion' }
  ];
  const [data, setData] = useState([]);
  const products = useSelector(state => state.productState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (products.product_type) {
      setData(products.product_type);
    } else {
      setData([]);
    }
  }, [products.product_type]);

  return (
    products.loading ? <CircularLoading /> :
      <MaterialTable
        title={language.title_view_products}
        columns={columns}
        data={data}
        options={{
          exportButton: true,
          headerStyle: {
            backgroundColor: '#ee2324',
            color: '#FFF'
          }
        }}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve,reject) => {
              if (appConfig.productionMode) {
                setTimeout(() => {
                  resolve();
                  const tblData = data;
                  tblData.push(newData);
                  dispatch(productEdit(tblData), "Add");
                }, 600);
              } else {
                alert(language.demo_mode);
                reject();
              }
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve,reject) => {
              if (appConfig.productionMode) {
                setTimeout(() => {
                  resolve();
                  const tblData = data;
                  tblData[tblData.indexOf(oldData)] = newData;
                  dispatch(productEdit(tblData), "Update");
                }, 600);
              } else {
                alert(language.demo_mode);
                reject();
              }
            }),
          onRowDelete: oldData =>
            new Promise((resolve,reject) => {
              if (appConfig.productionMode) {
                setTimeout(() => {
                  resolve();
                  const tblData = data;
                  tblData.splice(tblData.indexOf(oldData), 1);
                  dispatch(productEdit(tblData), "Delete");
                }, 600);
              } else {
                alert(language.demo_mode);
                reject();
              }
            }),
        }}
      />
  );
}