export const mainConfig = {
    appDetails: {
        app_name: "MG", 
        app_description: "The Mail & Guardian - Africa's Best Read",
        app_identifier: "com.mailandguardianonline",
        ios_app_version: "1.0.0", 
        android_app_version: 100
    },
    firebaseConf: {
        apiKey: "AIzaSyDTDmKHyQs4GHpvoYk-6BRQjIDpT-SFQig",
        authDomain: "mobileapp-8efe9.firebaseapp.com",
        databaseURL: "https://mobileapp-8efe9-default-rtdb.firebaseio.com",
        projectId: "mobileapp-8efe9",
        storageBucket: "mobileapp-8efe9.appspot.com",
        messagingSenderId: "524180566144",
        appId: "1:524180566144:web:05e72cd3a84691be617665",
        measurementId: "G-5Y89ML3B9B"
    },
    googleMapkey: "AIzaSyDRk-5pWTqY35a9-fZ8ch6crhltXJCObnM",
    facebookAppId: "2203444023255052"
}