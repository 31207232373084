import React,{ useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { appConfig, language } from 'config';
import { FirebaseContext } from 'common';

export default function Messaging() {
  const { api } = useContext(FirebaseContext);
  const {
    messageSend,
    messageEdit
  } = api;

  const columns =  [
      {
        title: language.device_type,
        field: 'devices',
        lookup: { All: 'All', ANDROID: 'Android', IOS: 'iOS' },
      },
      {
        title: language.subscriber_role,
        field: 'role',
        lookup: { subscriber: language.report_subscribers, vendor: language.report_vendors },
      },
      { title: language.word_title,field: 'title'},
      { title: language.chat_message_body, field: 'text' },
  ];

  const [data, setData] = useState([]);
  const messagedata = useSelector(state => state.messagingState);
  const dispatch = useDispatch();

  useEffect(()=>{
        if(messagedata.message){
            setData(messagedata.message);
        }
  },[messagedata.message]);

  return (
    messagedata.loading? <CircularLoading/>:
    <MaterialTable
      title={language.title_view_messages}
      columns={columns}
      data={data}
      options={{
        headerStyle: {
          backgroundColor: '#ee2324',
          color: '#FFF'
        }
      }}
      editable={{
        onRowAdd: newData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              const tblData = data;
              tblData.push(newData);
              appConfig.productionMode?
                dispatch(messageSend(newData))
                :
                alert(language.demo_mode);
              dispatch(messageEdit(newData,"Add"));
            }, 600);
          }),

          onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              const tblData = data;
              tblData[tblData.indexOf(oldData)] = newData;
              dispatch(messageEdit(newData,"Update"));
            }, 600);
          }),
        onRowDelete: newData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              dispatch(messageEdit(newData,"Delete"));
            }, 600);
          }),
      }}
    />
  );
}
