import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  InputAdornment,
  Icon,
  Paper,
  Tabs,
  Tab,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button as RegularButton
} from '@material-ui/core';
import Email from "@material-ui/icons/Email";
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import styles from "assets/jss/material-kit-react/views/loginPage.js";
import image from "assets/img/background.jpg";
import { useSelector, useDispatch } from "react-redux";
import AlertDialog from '../components/AlertDialog';
import CountrySelect from '../components/CountrySelect';

import {
  language,
  defaultCountry,
  appConfig
} from 'config';
import { FirebaseContext } from 'common';

const useStyles = makeStyles(styles);

export default function Login(props) {
  const { api, oneTimePin, captchaVerifier } = useContext(FirebaseContext);
  const {
    authLogin,
    authFacebook,
    authClear,
    mobileSignIn,
    signOut,
    authReset,
    authUserExist
  } = api;

  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const [loginMethod, setLoginMethod] = React.useState(0);
  const [activeReg, setActiveReg] = React.useState(false);
  const [openFPModal, setOpenFPModal] = React.useState(false);
  const [capatchaReady, setCapatchaReady] = React.useState(false);

  const [data, setData] = React.useState({
    email: '',
    pass: '',
    country: defaultCountry.phone,
    mobile: '',
    password: '',
    otp: '',
    verificationId: null,
    first_name: '',
    last_name: '',
    selectedcountry: defaultCountry,
    role: 'subscriber',
    referrer_code: ''
  });
  
  const [tabDisabled, setTabDisabled] = React.useState(false);
  const [fpEmail, setFpEmail] = React.useState("");

  const [alert, setAlert] = useState({ open: false, msg: '' });

  const classes = useStyles();
  const { ...rest } = props;

  useEffect(() => {
    if(!capatchaReady){
      window.recaptchaVerifier = new captchaVerifier("sign-in-button",{
        'size': 'invisible',
        'callback': function(response) {
          setCapatchaReady(true);
        }
      });
    }
    if (auth.info) {
      if(auth.info.profile){
        let role = auth.info.profile.role;
        if(role==='admin' || role==='manager'){
          props.history.push('/dashboard');
        }
        else if (role==='subscriber'){
          props.history.push('/salesorders');
        }
        else {
          appConfig.staticPages?props.history.push('/'):props.history.push('/salesorders');
        }
      }else{
        if(!activeReg){
          setActiveReg(true);
          if(auth.info.phoneNumber){
            setData({...data,mobile: auth.info.phoneNumber})
            setLoginMethod(1);
          }else{
            setData({...data,email: auth.info.email})
            setLoginMethod(0);
          }
          setTabDisabled(true);
          setAlert({ open: true, msg: language.output_logged_in });
        }
      } 
    }
    if (auth.error && auth.error.flag && auth.error.msg.message !== language.error_login_failed) {
      if (auth.error.msg.code !== 'info'){
        if (auth.error.msg.code === 'auth/user-not-found'){
          auth.error.msg.message = language.error_not_subscriber;
        }
        setAlert({ open: true, msg: auth.error.msg.message })
      }
    }
    if(auth.verificationId){
      setData({ ...data, verificationId: auth.verificationId });
    }
  }, [auth.info, auth.error, auth.verificationId, props.history, data, data.email,activeReg,capatchaReady,captchaVerifier]);

  const handleTabChange = (event, newValue) => {
    setLoginMethod(newValue);
  };

  const handleFacebook = (e) => {
    e.preventDefault();
    dispatch(authFacebook());
  }

  const alertClose = (e) => {
    e.preventDefault();
    setAlert({ open: false, msg: '' });
    if (auth.error.flag) {
      setData({...data,email:'',pass:''});
      dispatch(authClear());
    }
  };

  const onInputChange = (event) => {
    setData({ ...data, [event.target.id]: event.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    //eslint-disable-next-line
    if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(data.email) && data.pass.length > 0) {
      dispatch(authLogin(data.email, data.pass));
    } else {
      setAlert({ open: true, msg: language.error_credentials})
    }
    setData({...data,email:'',pass:''});
  }

  const handleGetOTP = (e) => {
    e.preventDefault();
    if (data.mobile.length < 10){
      console.log('No number');
      return;
    }
    // https://firebase.google.com/docs/reference/js/firebase.User#phonenumber
    const phoneNumber = "+" + data.country + data.mobile.substr(-9);
    authUserExist({mobile:phoneNumber}).then((res)=>{
      if(res.subscribers && res.subscribers.length > 0){
          const appVerifier = window.recaptchaVerifier;
          oneTimePin
          .signInWithPhoneNumber(phoneNumber, appVerifier)
          .then(res => {
              setData({...data, verificationId: res.verificationId})
          })
          .catch(error => {
              setAlert({ open: true, msg: error.code + ": " + error.message})
          });
      } else {
          if (res.error){
            setAlert({ open: true, msg: res.error});
          } else {
            setAlert({ open: true, msg: language.error_not_subscriber});
          }
      }
    });
  }

  const handleVerifyOTP = (e) => {
    e.preventDefault();
    if (data.otp.length === 6 && parseInt(data.otp) > 100000 & parseInt(data.otp) < 1000000) {
      dispatch(mobileSignIn(data.verificationId, data.otp));
    } else {
      setAlert({ open: true, msg: language.error_pin})
    }
  }

  const handleCancel = (e) => {
    e.preventDefault();
    dispatch(signOut());
    setTabDisabled(false);
    setActiveReg(false);  
  }

  const onCountryChange = (object, value) => {
    if (value && value.phone) {
      setData({ ...data, country: value.phone, selectedcountry:value });
    }
  };

  const handleRegister = (e) => {
    props.history.push('/register');
  };

  const handleForgotPass = (e) => {
    e.preventDefault();
    setOpenFPModal(true);
  };

  const onFPModalEmailChange = (e) => {
    e.preventDefault();
    setFpEmail(e.target.value);
  }

  const handleCloseFP = (e) => {
    e.preventDefault();
    setFpEmail('');
    setOpenFPModal(false);
  }

  const handleResetPassword = (e) => {
    e.preventDefault();
    dispatch(authReset(fpEmail));
    setFpEmail('');
    setOpenFPModal(false);
  }

  return (
    <div>
      <Header
        absolute
        color="transparent"
        rightLinks={<HeaderLinks />}
        {...rest}
      />
      <div
        className={classes.pageHeader}
        style={{
          backgroundImage: "url(" + image + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center"
        }}
      >
        <div id="sign-in-button" />
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card>
                <form className={classes.form}>
                  {appConfig.facebookLogin?
                  <CardHeader color="info" className={classes.cardHeader}>
                    <h4>{language.title_view_login}</h4>
                    <div className={classes.socialLine}>
                      {appConfig.facebookLogin?
                      <Button
                        justIcon
                        href="#pablo"
                        target="_blank"
                        color="transparent"

                        onClick={handleFacebook}
                      >
                        <i className={"fab fa-facebook"} />
                      </Button>
                      :null}
                    </div>
                  </CardHeader>
                  :null}
                  <Paper square className={classes.root} style={!(appConfig.facebookLogin)?{paddingTop:20,borderTopLeftRadius:10,borderTopRightRadius:10}:null}>
                    <Tabs
                      value={loginMethod}
                      onChange={handleTabChange}
                      variant="fullWidth"
                      indicatorColor="primary"
                      textColor="inherit"
                      aria-label="switch login type"
                    >
                      <Tab disabled={tabDisabled} icon={<EmailIcon />} aria-label="email" />
                      {appConfig.mobileLogin?
                      <Tab disabled={tabDisabled} icon={<PhoneIcon />} aria-label="phone" />
                      :null}
                    </Tabs>
                  </Paper>

                  <CardBody>
                    {loginMethod === 0 ?    //EMAIL
                      <CustomInput
                        labelText={language.subscriber_email}
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "email",
                          required: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Email className={classes.inputIconsColor} />
                            </InputAdornment>
                          )
                        }}
                        onChange={onInputChange}
                        value={data.email}
                      />
                      : null}
                    {loginMethod === 0?
                      <CustomInput
                        labelText={language.subscriber_password}
                        id="pass"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "password",
                          required: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Icon className={classes.inputIconsColor}>
                                lock_outline
                            </Icon>
                            </InputAdornment>
                          ),
                          autoComplete: "off"
                        }}
                        onChange={onInputChange}
                        value={data.pass}
                      />
                      : null}
                    { loginMethod === 1 && appConfig.showcountryList ?   // COUNTRY
                      <CountrySelect
                        value={data.selectedcountry}
                        onChange={onCountryChange}
                        style={{paddingTop:20}}
                        disabled={data.verificationId ? true : false}
                      />
                      : null}
                    {loginMethod === 1 ?   //MOBILE
                      <CustomInput
                        labelText={language.subscriber_mobile}
                        id="mobile"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required: true,
                          disabled: data.verificationId ? true : false,
                          endAdornment: (
                            <InputAdornment position="end">
                              <PhoneIcon className={classes.inputIconsColor} />
                            </InputAdornment>
                          )
                        }}
                        onChange={onInputChange}
                        value={data.mobile}
                      />
                      : null}
                    {data.verificationId && loginMethod === 1 ?    // OTP
                      <CustomInput
                        labelText={language.subscriber_otp}
                        id="otp"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          type: "password",
                          required: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Icon className={classes.inputIconsColor}>
                                lock_outline
                            </Icon>
                            </InputAdornment>
                          ),
                          autoComplete: "off"
                        }}
                        onChange={onInputChange}
                        value={data.otp}
                      />
                      : null}
                    {loginMethod === 0 ?  
                      <RegularButton 
                        color="inherit" 
                        onClick={handleForgotPass}
                        disableElevation={true}
                        disableFocusRipple={true}
                        disableRipple={true}
                        className={classes.forgotButton}
                        variant="text"
                      >
                          {language.input_forgot_password}
                      </RegularButton>
                    : null}
                  </CardBody>
                  <CardFooter className={classes.cardFooter}>
                    {loginMethod === 0 ?
                      <Button className={classes.normalButton} simple color="primary" size="lg" onClick={handleSubmit}>
                        {language.input_login}
                    </Button>
                      : null}
                    {loginMethod === 0 ?
                      <Button className={classes.normalButton} simple color="primary" size="lg" onClick={handleRegister}>
                        {language.input_register}
                    </Button>
                      : null}
                    {loginMethod === 1 && !data.verificationId ?
                      <Button className={classes.normalButton} simple color="primary" size="lg" onClick={handleGetOTP}>
                        {language.input_send_otp}
                    </Button>
                      : null}
                    { loginMethod === 1 &&  data.verificationId ?
                      <Button className={classes.normalButton} simple color="primary" size="lg" onClick={handleVerifyOTP}>
                        {language.input_verify}
                    </Button>
                      : null}
                    {loginMethod === 1 && !data.verificationId ?
                      <Button className={classes.normalButton} simple color="primary" size="lg" onClick={handleRegister}>
                        {language.input_register}
                    </Button>
                      : null}
                    { loginMethod === 1 && data.verificationId ?
                      <Button className={classes.normalButton} simple color="primary" size="lg" onClick={handleCancel}>
                        {language.input_cancel}
                    </Button>
                      : null}

                  </CardFooter>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
        <Footer whiteFont />
        <Dialog open={openFPModal} onClose={handleCloseFP} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{language.title_forgot}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {language.output_password_forgot}
          </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label={language.subscriber_email}
              type="email"
              fullWidth
              onChange={onFPModalEmailChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseFP} color="primary">
            {language.input_cancel}
          </Button>
            <Button onClick={handleResetPassword} color="primary">
            {language.input_reset_password}
          </Button>
          </DialogActions>
        </Dialog>
        <AlertDialog open={alert.open} onClose={alertClose}>{alert.msg}</AlertDialog>
      </div>
    </div>
  );
}
