import React, { useState, useEffect, useContext } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import {
  appConfig,
  language
} from 'config';
import { FirebaseContext } from 'common';

export default function Modes() {
  const { api } = useContext(FirebaseContext);
  const {
    modeEdit
  } = api;
  const columns = [
    { title: language.mode_name, field: 'name' },
    { title: language.mode_description, field: 'desc' },
    { title: language.mode_subs_level, field: 'min_subs', type: 'numeric' },
    { title: language.mode_min_age, field: 'min_age', type: 'numeric' },
    { title: language.mode_max_age, field: 'max_age', type: 'numeric' }
  ];
  const [data, setData] = useState([]);
  const modedata = useSelector(state => state.modeState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (modedata.modes) {
      setData(modedata.modes);
    } else {
      setData([]);
    }
  }, [modedata.modes]);

  return (
    modedata.loading ? <CircularLoading /> :
      <MaterialTable
        title={language.title_view_appmodes}
        columns={columns}
        data={data}
        options={{
          exportButton: true,
          headerStyle: {
            backgroundColor: '#ee2324',
            color: '#FFF'
          }
        }}
        editable={{
          onRowAdd: newData =>
            new Promise((resolve,reject) => {
              if (appConfig.productionMode) {
                setTimeout(() => {
                  resolve();
                  const tblData = data;
                  tblData.push(newData);
                  dispatch(modeEdit(tblData), "Add");
                }, 600);
              } else {
                alert(language.demo_mode);
                reject();
              }
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve,reject) => {
              if (appConfig.productionMode) {
                setTimeout(() => {
                  resolve();
                  const tblData = data;
                  tblData[tblData.indexOf(oldData)] = newData;
                  dispatch(modeEdit(tblData), "Update");
                }, 600);
              } else {
                alert(language.demo_mode);
                reject();
              }
            }),
          onRowDelete: oldData =>
            new Promise((resolve,reject) => {
              if (appConfig.productionMode) {
                setTimeout(() => {
                  resolve();
                  const tblData = data;
                  tblData.splice(tblData.indexOf(oldData), 1);
                  dispatch(modeEdit(tblData), "Delete");
                }, 600);
              } else {
                alert(language.demo_mode);
                reject();
              }
            }),
        }}
      />
  );
}