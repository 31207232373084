import React,{ useEffect, useContext } from 'react';
import CircularLoading from "../components/CircularLoading";
import { useSelector, useDispatch } from "react-redux";
import {language} from 'config';
import { FirebaseContext } from 'common';

function AuthLoading(props) {
    const { api } = useContext(FirebaseContext);
    const {
        authFetch,
        productsFetch,
        modesFetch,
        settingsFetch, 
        orderFetch,
        metadataFetch,
        vouchersFetch,
        earningsFetch,
        subscribersFetch,
        messagesFetch,
        revenueFetch,
        signOut,
        payoutsFetch,
    } = api;
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth);

    useEffect(()=>{
        dispatch(authFetch());
        dispatch(modesFetch());
        dispatch(productsFetch());
        dispatch(settingsFetch());
    },[dispatch,authFetch,modesFetch,productsFetch,settingsFetch]);

    useEffect(()=>{
        if(auth.info){
            if(auth.info.profile){
                let role = auth.info.profile.role;
                if(role === 'subscriber'){
                    dispatch(orderFetch(auth.info.uid,role));
                    dispatch(metadataFetch());
                }
                else if(role === 'vendor'){
                    dispatch(orderFetch(auth.info.uid,role));
                }
                else if(role === 'admin'){
                    dispatch(subscribersFetch());
                    dispatch(orderFetch(auth.info.uid,role));
                    dispatch(vouchersFetch());
                    dispatch(earningsFetch(auth.info.uid,role));
                    dispatch(messagesFetch());
                    dispatch(revenueFetch());
                    dispatch(metadataFetch());
                    dispatch(payoutsFetch());
                }
                else if(role === 'manager'){
                    dispatch(subscribersFetch());
                    dispatch(orderFetch(auth.info.uid,role));
                    dispatch(earningsFetch(auth.info.uid,role));
                }
                else{
                    alert(language.error_invalid_role);
                    dispatch(signOut());
                }
            }else{
                alert(language.subscribers_issue_contact_admin);
                dispatch(signOut());
            }
        }
    },[auth.info,dispatch,orderFetch,metadataFetch,earningsFetch,revenueFetch,messagesFetch,vouchersFetch,subscribersFetch,payoutsFetch,signOut]);

    return (
        auth.loading? <CircularLoading/>:props.children
    )
}

export default AuthLoading;