import React,{ useState,useEffect } from 'react';
import {
    Grid,
    Paper,
    Typography
} from '@material-ui/core';
import DashboardCard from '../components/DashboardCard';
import Map from '../components/Map';
import { useSelector} from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { 
    language,
    googleMapkey 
} from "config";

const Dashboard = () => {
    const [mylocation, setMylocation] = useState(null);
    const [locations, setLocations] = useState([]);
    const [dailygross,setDailygross] = useState(0);
    const [monthlygross,setMonthlygross] = useState(0);
    const [totalgross,setTotalgross] = useState(0);

    const [settings,setSettings] = useState({});

    const subscriberData = useSelector(state => state.subscriberState);
    const salesOrders = useSelector(state => state.orderState);
    const settingsdata = useSelector(state => state.settingState);
    const auth = useSelector(state => state.auth);

    useEffect(()=>{
        if(mylocation == null){
            navigator.geolocation.getCurrentPosition(
                position => setMylocation({ 
                    lat: position.coords.latitude, 
                    lng: position.coords.longitude
                }), 
                err => console.log(err)
            );
        }
    },[mylocation]);

    useEffect(()=>{
        if(settingsdata.settings){
          setSettings(settingsdata.settings);
        }
    },[settingsdata.settings]);

    useEffect(()=>{
        if(subscriberData.subscribers){
            const subscriber = subscriberData.subscribers.filter(user => user.role ==='vendor' && ((user.manager_role === auth.info.uid && auth.info.profile.role === 'manager')|| auth.info.profile.role === 'admin'));  
            let locs = [];
            for(let i=0;i<subscriber.length;i++){
                if(subscriber[i].account_approved && subscriber[i].account_active && subscriber[i].location){
                    locs.push({
                        id: i,
                        lat: subscriber[i].location.lat,
                        lng: subscriber[i].location.lng,
                        person: subscriber[i].first_name + ' ' + subscriber[i].last_name
                    });
                }
            }
            setLocations(locs);
        }
    },[subscriberData.subscribers,auth.info.profile,auth.info.uid]);

    useEffect(()=>{
        if(salesOrders.orders){
            let today =  new Date();
            let monthly = 0;
            let total = 0;
            let daily = 0;
            for(let i=0;i<salesOrders.orders.length;i++){
                if(salesOrders.orders[i].order_status === 'PAID' || salesOrders.orders[i].order_status === 'COMPLETED'){
                    const {invoice_date,service_fee} = salesOrders.orders[i];
                    let tDate = new Date(invoice_date);
                    if(service_fee>0){

                        if(tDate.getMonth() === today.getMonth() && tDate.getFullYear() === today.getFullYear()){
                            monthly = monthly + parseFloat(service_fee);
                        }
                        if(tDate.getDate() === today.getDate() && tDate.getMonth() === today.getMonth()){
                            daily  = daily + parseFloat(service_fee);
                        } 
                        total  = total + parseFloat(service_fee);
                    }
                }
            }
            setDailygross(parseFloat(daily).toFixed(2));
            setMonthlygross(parseFloat(monthly).toFixed(2));
            setTotalgross(parseFloat(total).toFixed(2));
        }
    },[salesOrders.orders]);
    
    return (
        salesOrders.loading || subscriberData.loading ? <CircularLoading/> :
        <div>
            <Typography variant="h4" style={{margin:"20px 0 0 15px"}}>{language.title_dashboard}</Typography>
            <Grid container direction="row" spacing={2}>
                <Grid item xs>
                    <DashboardCard title={language.word_today} image={require("../assets/img/money1.jpg")}>{ settings.symbol + ' ' + dailygross}</DashboardCard>
                </Grid>
                <Grid item xs>
                    <DashboardCard title={language.word_month} image={require("../assets/img/money2.jpg")}>{ settings.symbol +' ' +  monthlygross}</DashboardCard>
                </Grid>
                <Grid item xs>
                    <DashboardCard title={language.report_total_income} image={require("../assets/img/money3.jpg")}>{ settings.symbol +' ' +  totalgross}</DashboardCard>
                </Grid>
            </Grid>
            { mylocation?
            <Paper style={{marginTop:'25px'}}>
                <Typography variant="h4" style={{margin:"20px 0 0 15px"}}>{language.real_time_view}</Typography>
                <Map mapcenter={mylocation} locations={locations}
                    googleMapURL={"https://maps.googleapis.com/maps/api/js?key=" + googleMapkey + "&v=3.exp&libraries=geometry,drawing,places"}
                    loadingElement={<div style={{ height: `480px` }} />}
                    containerElement={<div style={{ height: `480px` }} />}
                    mapElement={<div style={{ height: `480px` }} />}
                />
            </Paper>
            :
            <Typography variant="h6" style={{margin:"20px 0 0 15px",color:'#FF0000'}}>{language.title_enable_location}</Typography>
            }
        </div>
        
    )
}

export default Dashboard;