import React, { useState, useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from "react-redux";
import AlertDialog from '../components/AlertDialog';
import CircularLoading from "../components/CircularLoading";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import { appDetails } from 'config';
import {
  appConfig,
  language
} from 'config';
import { FirebaseContext } from 'common';

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    alignContent: 'center'
  },
  gridcontainer: {
    alignContent: 'center'
  },
  items: {
    margin: 0,
    width: '100%'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    width: 192,
    height: 192
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const Settings = (props) => {
  const { api } = useContext(FirebaseContext);
  const {
    settingEdit,
    settingsClear
  } = api;
  const settingsdata = useSelector(state => state.settingState);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [data, setData] = useState({
    currency: 'ZAR',
    symbol: 'R',
    bonus: 0,
    panic: '',
    otp_secure: false,
    service_approval: false,
    email_verify: false,
    sound_repeat: false,
    CompanyName: '',
    CompanyWebsite: '',
    CompanyTerms: '',
    social_twitter: '',
    social_facebook: '',
    social_instagram: '',
    store_apple: '',
    store_google: '',
    license: '',
    contact_email: '',
    convert_to_mile: false
  });
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (settingsdata.settings) {
      setData(settingsdata.settings);
    }
  }, [settingsdata.settings]);

  const handleSwitchChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.checked });
  };

  const handleTextChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleBonusChange = (e) => {
    setData({ ...data, bonus: parseFloat(e.target.value) >= 0 ? parseFloat(e.target.value) : '' });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (appConfig.productionMode) {
      if (data.bonus === '') {
        alert(language.error_try_again)
      } else {
        setClicked(true);
        dispatch(settingEdit(data));
      }
    } else {
      alert(language.demo_mode);
    }
  }

  const handleClose = () => {
    setClicked(false);
    dispatch(settingsClear());
  };

  return (
    settingsdata.loading ? <CircularLoading /> :
      <form className={classes.form} onSubmit={handleSubmit}>
        <Grid container spacing={2} >
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography component="h1" variant="h5" style={{marginTop:8}}>
                {language.app_name}
            </Typography>
            <TextField
              disabled={true}
              variant="outlined"
              margin="normal"
              fullWidth
              id="app_name"
              label={language.app_name}
              value={appDetails.app_name}
            />
            <Typography component="h1" variant="h5">
              {language.settings_currency}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="symbol"
                  label={language.settings_currency_symbol}
                  name="symbol"
                  autoComplete="symbol"
                  onChange={handleTextChange}
                  value={data.symbol}
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="currency"
                  label={language.settings_currency_code}
                  name="currency"
                  autoComplete="currency"
                  onChange={handleTextChange}
                  value={data.currency}
                />
              </Grid>
            </Grid>
            <Typography component="h1" variant="h5">
              {language.settings_referral_bonus}
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="bonus"
              label={language.settings_referral_bonus}
              name="bonus"
              autoComplete="bonus"
              onChange={handleBonusChange}
              value={data.bonus}
            />
            <Typography component="h1" variant="h5" style={{marginTop:8}}>
              {language.title_settings}
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="panic"
              label={language.settings_panic_nunber}
              name="panic"
              autoComplete="panic"
              onChange={handleTextChange}
              value={data.panic}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={data.otp_secure}
                  onChange={handleSwitchChange}
                  name="otp_secure"
                  color="primary"
                />
              }
              label={language.settings_label3}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={data.service_approval}
                  onChange={handleSwitchChange}
                  name="service_approval"
                  color="primary"
                />
              }
              label={language.settings_label4}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={data.email_verify}
                  onChange={handleSwitchChange}
                  name="email_verify"
                  color="primary"
                />
              }
              label={language.settings_label5}
            />
            <Typography component="h1" variant="h5" style={{marginTop:'10px'}}>
              {language.settings_advanced}
            </Typography>
            <FormControlLabel style={{ marginTop: '10px' }}
              control={
                <Switch
                  checked={data.sound_repeat}
                  onChange={handleSwitchChange}
                  name="sound_repeat"
                  color="primary"
                />
              }
              label={language.settings_repeat_sound}
            />
            <FormControlLabel style={{ marginTop: '10px' }}
              control={
                <Switch
                  checked={data.convert_to_mile}
                  onChange={handleSwitchChange}
                  name="convert_to_mile"
                  color="primary"
                />
              }
              label={language.convert_to_mile}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography component="h1" variant="h5">
              {language.app_info}
            </Typography>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="CompanyName"
              label={language.settings_company_name}
              name="CompanyName"
              autoComplete="CompanyName"
              onChange={handleTextChange}
              value={data.CompanyName}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="contact_email"
              label={language.subscriber_email}
              name="contact_email"
              autoComplete="contact_email"
              onChange={handleTextChange}
              value={data.contact_email}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="CompanyWebsite"
              label={language.settings_company_website}
              name="CompanyWebsite"
              autoComplete="CompanyWebsite"
              onChange={handleTextChange}
              value={data.CompanyWebsite}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="CompanyTerms"
              label={language.link_terms}
              name="CompanyTerms"
              autoComplete="CompanyTerms"
              onChange={handleTextChange}
              value={data.CompanyTerms}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="social_facebook"
              label={language.social_facebook}
              name="social_facebook"
              autoComplete="social_facebook"
              onChange={handleTextChange}
              value={data.social_facebook}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="social_twitter"
              label={language.social_twitter}
              name="social_twitter"
              autoComplete="social_twitter"
              onChange={handleTextChange}
              value={data.social_twitter}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="social_instagram"
              label={language.social_instagram}
              name="social_instagram"
              autoComplete="social_instagram"
              onChange={handleTextChange}
              value={data.social_instagram}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="store_apple"
              label={language.settings_apple}
              name="store_apple"
              autoComplete="store_apple"
              onChange={handleTextChange}
              value={data.store_apple}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="store_google"
              label={language.settings_google}
              name="store_google"
              autoComplete="store_google"
              onChange={handleTextChange}
              value={data.store_google}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {language.input_submit}
            </Button>
          </Grid>
        </Grid>
        <AlertDialog open={settingsdata.error.flag && clicked} onClose={handleClose}>{language.error_update}</AlertDialog>
      </form>
  );

}

export default Settings;
